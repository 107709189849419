import { CDN_URL } from 'constants/baseConstants';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { UserManager } from 'oidc-client';
import { revokeToken } from 'services/login';

export const getIconUrl = (iconName: string, type = 'svg'): string => `${CDN_URL}/images/ss/icons/${iconName}.${type}`;

const getCookieDomain = () => {
  const cookieRootDomain = _env.COOKIE_ROOT_DOMAIN;
  if (!cookieRootDomain) {
    return '';
  }
  if (cookieRootDomain.indexOf('.') === 0) {
    return `domain=${cookieRootDomain};`;
  }
  return `domain=.${cookieRootDomain};`;
};

export const setCookie = (cname: string, cvalue: unknown, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  const cookie = cname + '=' + cvalue + ';' + expires + `;${getCookieDomain()}path=/;`;
  document.cookie = cookie;
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkCookie = (cname: string) => {
  const name = getCookie(cname);

  if (!name || name === '0' || name === 'false') {
    return false;
  }
  return true;
};

export const signOut = (usrManager: UserManager) => {
  usrManager.getUser().then(async (user: any) => {
    await revokeToken({
      token: user.access_token,
      client_id: _env.CLIENT_ID,
      sub: user.profile.sub,
    });
    usrManager
      .signoutRedirect({
        prompt: 'login',
        id_token_hint: user.id_token,
        extraQueryParams: { ui_locales: localStorage.getItem(PERSONAL_CONSTANTS.LANGUAGE) },
      })
      .then(() => {
        sessionStorage.clear();
      })
      .catch((err) => console.log('signout error', err));
  });
};

export const replaceToHTMLElement = (str = ''): string =>
  str
    .replace(/\[\\n\]/g, '<br/>')
    .replace(/\[\n\]/g, '<br/>')
    .replace(/\[b\]/g, '<b>')
    .replace(/\[\/b\]/g, '</b>')
    .replace(/\[span\]/g, '<span>')
    .replace(/\[\/span\]/g, '</span>');

export const updateElementHeight = (className: string) => {
  const elements: any = document.getElementsByClassName(className);
  let maxHeight = 0;
  for (let i = 0; i < elements.length; i++) {
    const elementHeight = elements[i].clientHeight;
    if (elementHeight > maxHeight) {
      maxHeight = elementHeight;
    }
  }

  for (let i = 0; i < elements.length; i++) {
    elements[i].style['height'] = `${maxHeight}px`;
  }
};

export const resetHeight = (className: string) => {
  const elements: any = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
    elements[i].style['height'] = `auto`;
  }
};

export const isNumber = (n: string) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};


export const elementExistInDom = (element: string) => {
  return document.body.contains(document.querySelector(element))
}