import { IMarketingPreferences, IPersonalBody } from 'types/IPersonal';

export const EDIT_PERSONAL_INFO = "personal/EDIT_PERSONAL_INFO";
export interface EditPersonalInfoAction {
  type: typeof EDIT_PERSONAL_INFO;
  memberId: number;
  body: IPersonalBody;
}

export const EDIT_PERSONAL_INFO_SUCCESS = 'personal/EDIT_PERSONAL_INFO_SUCCESS';
export interface EditPersonalInfoSucessAction {
  type: typeof EDIT_PERSONAL_INFO_SUCCESS;
  isEditSuccess: boolean;
}

export const EDIT_PERSONAL_INFO_FAIL = 'personal/EDIT_PERSONAL_INFO_FAIL';
export interface EditPersonalInfoFailAction {
  type: typeof EDIT_PERSONAL_INFO_FAIL;
  error: any;
}

export const GET_CONFIG = 'personal/GET_CONFIG';
export interface GetConfigAction {
  type: typeof GET_CONFIG;
}

export const GET_CONFIG_SUCCESS = 'personal/GET_CONFIG_SUCCESS';
export interface GetConfigSuccessAction {
  type: typeof GET_CONFIG_SUCCESS;
  regexs: Array<{ key: string; value: string }>;
}

export const GET_CONFIG_FAIL = 'personal/GET_CONFIG_FAIL';
export interface GetConfigFailAction {
  type: typeof GET_CONFIG_FAIL;
  error: any;
}

export const RESET_PERSONAL_STATE = 'personal/RESET_PERSONAL_STATE';
export interface ResetPersonalStateAction {
  type: typeof RESET_PERSONAL_STATE;
}

export const GET_PERSONAL_FIELDS = 'personal/GET_PERSONAL_FIELDS';
export interface GetFieldsPersonalAction {
  type: typeof GET_PERSONAL_FIELDS;
  featureCode: string;
}

export const GET_PERSONAL_FIELDS_SUCCESS = 'personal/GET_PERSONAL_FIELDS_SUCCESS';
export interface GetFieldsPersonalSuccessAction {
  type: typeof GET_PERSONAL_FIELDS_SUCCESS;
  fields: Array<{
    id: number;
    tenantId: string;
    subTenantId: string;
    name: string;
    isVisible: boolean;
    isRequired: boolean;
    editable: boolean;
    maxLength: number;
    key: string;
    children: any;
  }>;
}

export const GET_PERSONAL_FIELDS_FAIL = 'personal/GET_PERSONAL_FIELDS_FAIL';
export interface GetFieldsPersonalFailAction {
  type: typeof GET_PERSONAL_FIELDS_FAIL;
  error: any;
}

export const UPDATE_MARKETING_PREFERENCES = 'personal/UPDATE_MARKETING_PREFERENCES';
export interface UpdateMarketingPreferencesAction {
  type: typeof UPDATE_MARKETING_PREFERENCES;
  memberId: number;
  body: IMarketingPreferences;
}

export const UPDATE_MARKETING_PREFERENCES_SUCCESS = 'personal/UPDATE_MARKETING_PREFERENCES_SUCCESS';
export interface UpdateMarketingPreferencesSucessAction {
  type: typeof UPDATE_MARKETING_PREFERENCES_SUCCESS;
  isEditSuccess: boolean;
}

export const UPDATE_MARKETING_PREFERENCES_FAIL = 'personal/UPDATE_MARKETING_PREFERENCES_FAIL';
export interface UpdateMarketingPreferencesFailAction {
  type: typeof UPDATE_MARKETING_PREFERENCES_FAIL;
  error: any;
}

export type MembershipAction =
  | EditPersonalInfoAction
  | EditPersonalInfoSucessAction
  | EditPersonalInfoFailAction
  | ResetPersonalStateAction
  | GetConfigAction
  | GetConfigSuccessAction
  | GetConfigFailAction
  | GetFieldsPersonalAction
  | GetFieldsPersonalSuccessAction
  | GetFieldsPersonalFailAction
  | UpdateMarketingPreferencesAction
  | UpdateMarketingPreferencesSucessAction
  | UpdateMarketingPreferencesFailAction;
