import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import PERSONAL_CONSTANTS from "constants/personalConstant";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT,
    load: "currentOnly",
    detection: {
      order: ["querystring", "localStorage"],
      lookupFromPathIndex: 0,
      lookupLocalStorage: PERSONAL_CONSTANTS.LANGUAGE,
      lookupQuerystring: PERSONAL_CONSTANTS.LANGUAGE,
    },
    backend: {
      loadPath: `/i18n/${_env.COUNTRY_CODE.toLowerCase()}/{{lng}}.json?timestamp=${Date.now().toString()}`,
    },
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,
    keySeparator: false,
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
