import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';

import Addon from 'components/Addon';
import { AddonInterface } from 'components/Addon/types';
import AddonsMobile from 'components/AddonsMobile';
import CONSTANTS from 'constants/commonContants';
import { Card } from '@material-ui/core';
import { CrownIcon } from 'components/Icons';
import ListClubs from 'components/ListClubs';
import { UPGRADE_MEMBERSHIP_URL } from 'utility/routesURL';
import UpgradeThankyouSummary from 'components/UpgradeThankyouSummary';
import commonConstants from 'constants/commonContants';
import { getAddons } from 'services/membership';
import { getIconUrl } from 'utility/helpers';
import moment from 'moment';
import { paymentActions } from 'redux/actionCreators';
import { useDeviceCheck } from 'utility/screenDetect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { DONE_MAKE_PAYMENT_TYPES } from 'constants/paymentConstants';
import emitNotify from 'utility/emitNotify';
import { usePath, useQuery } from 'hooks';

interface MembershipInfoProps {
  setOpenAddons: Function;
  openAddons: boolean;
  setOpenAccessClubs: Function;
  openAccessClubs: boolean;
  isAllowAddAddon: boolean;
  setToggleMenu: (isShow: boolean) => void;
}

export const MembershipInfo = (props: MembershipInfoProps) => {
  const { setOpenAddons, openAddons, setOpenAccessClubs, openAccessClubs, isAllowAddAddon, setToggleMenu } = props;
  const [, , isMobile, isTablet] = useDeviceCheck();
  const [enablePlan, setEnablePlan] = useState(false);
  const [loadingAddons, setLoadingAddons] = useState(true);
  const [showThankUpgrade, setShowThankUpgrade] = useState(false);
  const [addons, setAddons] = useState<AddonInterface[]>([]);

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { regexs } = useTypedSelector((state) => state.personal);
  const brands = useTypedSelector((state) => state.membership.brands);
  const { clearPaymentState } = paymentActions;
  const { doneMakePayment, doneMakePaymentWithReceived, donePayWithRegisterCard, doneUpdatePayment } = useTypedSelector(
    (state) => state.payment
  );
  const clearPaymentAction = () => dispatch(clearPaymentState());

  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);

  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const { enqueueSnackbar } = useSnackbar();

  const user = useTypedSelector((state) => state.login.user);
  const brandCode = useMemo(() => user.profile?.brandCode || '', [user]);
  const query = useQuery();

  const isAllowUpgradeMembership = useMemo(() => {
    return (
      membershipDetails.clubTierCode === commonConstants.VISUAL_STUDIO_CLUB_CODE &&
      isAllowAddAddon &&
      (brandCode === commonConstants.CF_BRAND || brandCode === commonConstants.FF_BRAND)
    );
  }, [membershipDetails, isAllowAddAddon, brandCode]);
  

  const getAddonsData = useCallback(async () => {
    try {
      const data = await getAddons(membershipDetails.memberId);
      setAddons(data);
      setLoadingAddons(false);
    } catch (e: any) {
      console.log(e);
    }
  }, [membershipDetails.memberId, membershipDetails.currentBalance]);

  useEffect(() => {
    setLoadingAddons(true);
    getAddonsData();
  }, [getAddonsData, membershipDetails.addons.length]);

  useEffect(() => {
    switch (true) {
      case doneUpdatePayment:
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_PAYMENT_AND_CREDITS'),
          t('SS_NOTIFICATION_PAYMENT_UPDATE_SAVING'),
          'checked'
        );
        clearPaymentAction();
        break;
      case doneMakePaymentWithReceived === DONE_MAKE_PAYMENT_TYPES.NORMAL:
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_PAYMENT_AND_CREDITS'),
          t('SS_NOTIFICATION_PAYMENT_MAKE_PAYMENT_PROCESSING'),
          'success'
        );
        clearPaymentAction();
        break;
      case doneMakePaymentWithReceived === DONE_MAKE_PAYMENT_TYPES.ADDONS:
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_MEMBERSHIP'),
          t('SS_NOTIFICATION_PERSONAL_INFORMATION_SAVING'),
          'success'
        );
        clearPaymentAction();
        break;
      case doneMakePayment === DONE_MAKE_PAYMENT_TYPES.NORMAL:
      case donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.NORMAL:
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_PAYMENT_AND_CREDITS'),
          t('SS_NOTIFICATION_PAYMENT_MAKE_PAYMENT_PROCESSING'),
          'success'
        );
        clearPaymentAction();
        break;
      case doneMakePayment === DONE_MAKE_PAYMENT_TYPES.ADDONS:
      case donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.ADDONS:
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_MEMBERSHIP'),
          t('SS_NOTIFICATION_PERSONAL_INFORMATION_SAVING'),
          'success'
        );
        clearPaymentAction();
        break;
      case doneMakePayment === DONE_MAKE_PAYMENT_TYPES.UPGRADE:
      case donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.UPGRADE:
        clearPaymentAction();
        setShowThankUpgrade(true);
        break;
      default:
        break;
    }
  }, [doneUpdatePayment, doneMakePayment, doneMakePaymentWithReceived, donePayWithRegisterCard]);

  const countClub = useMemo(
    () =>
      brands.reduce(
        (total: number, br: any) =>
          total + br.clubs.reduce((subTotal: number, club: any) => subTotal + ((!!club.clubWebsiteName && 1) || 0), 0),
        0
      ),
    [JSON.stringify(brands)]
  );

  const setDisplayMenu = (bool: boolean) => {
    setOpenAddons(bool);
  };

  const setDisplayClubs = (bool: boolean) => {
    setOpenAccessClubs(bool);
  };

  if (Object.keys(membershipDetails).length === 0) return null;

  if (openAddons) {
    return (
      <AddonsMobile closeAddons={() => setDisplayMenu(false)} addons={membershipDetails && membershipDetails.addons} />
    );
  }

  if (openAccessClubs) {
    return <ListClubs handleClose={() => setDisplayClubs(false)} />;
  }

  const showAddons = (addons: Array<any>) => {
    if (addons && membershipDetails.addons.length > 0) {
      gtag('event', 'Link', {
        event_action: 'MCheckAddon',
        event_label: 'MCheckAddonClicked',
      });
      setDisplayMenu(true);
    }
  };

  const showClubs = () => {
    if (brands && countClub > 0) {
      gtag('event', 'Link', {
        event_action: 'MCheckClubAccess',
        event_label: 'MCheckClubAccessClicked',
      });
      setDisplayClubs(true);
    }
  };

  const handleOnClickUpgradeMembership = () => {
    gtag('event', 'Button', {
      event_action: 'MUpgradeMembership',
      event_label: 'MUpgradeMembershipClicked',
    });
    sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP);
    sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW);
    history.push(UPGRADE_MEMBERSHIP_URL);
  };

  const renderMemberInfo = () => {
    if (isMobile) {
      return (
        <div className="card-info__info-group">
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Homeclub')} alt="home-club" />
              <span>{t('SS_MEMBERSHIP_HOME_CLUB')}</span>
            </div>
            <span>{membershipDetails.clubName && membershipDetails.clubName}</span>
          </div>
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Package')} alt="package" />
              <span>{t('SS_MEMBERSHIP_PACKAGE')}</span>
            </div>
            <span>{membershipDetails.clusterName && membershipDetails.clusterName}</span>
          </div>
          {membershipDetails.isLumpSumPlan && (
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Access')} alt="access" />
                <span>{t('SS_MEMBERSHIP_CONTRACT_END_DATE')}</span>
              </div>
              <span>
                {moment(membershipDetails.packages && membershipDetails.packages[0].endDate).format(
                  dateFormat.value.toUpperCase()
                )}
              </span>
            </div>
          )}
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Plan')} alt="plan" />
              <span>{t('SS_MEMBERSHIP_PLAN')}</span>
            </div>
            <span>{membershipDetails.isLumpSumPlan ? t('SS_MEMBERSHIP_LUMP_SUM') : t('SS_MEMBERSHIP_RECURRING')}</span>
          </div>
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Addons')} alt="add-ons" />
              <span>{t('SS_MEMBERSHIP_ADDONS')}</span>
            </div>
            <span className="card-info__add-ons" onClick={() => showAddons(membershipDetails.addons)}>
              {membershipDetails.addons.length > 1
                ? `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEMS')}`
                : `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEM')}`}{' '}
            </span>
          </div>
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Access')} alt="access" />
              <span>{t('SS_MEMBERSHIP_ACCESS')}</span>
            </div>
            <span className="card-info__add-ons" onClick={() => showClubs()}>
              {countClub > 1 ? `${countClub} ${t('SS_MEMBERSHIP_CLUBS')}` : `${countClub} ${t('SS_MEMBERSHIP_CLUB')}`}{' '}
            </span>
          </div>
        </div>
      );
    }

    if (isTablet) {
      return (
        <div className="card-info__info-group">
          <div className="card-info__column-first">
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Homeclub')} alt="home-club" />
                <span>{t('SS_MEMBERSHIP_HOME_CLUB')}</span>
              </div>
              <span>{membershipDetails.clubName && membershipDetails.clubName}</span>
            </div>
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Package')} alt="package" />
                <span>{t('SS_MEMBERSHIP_PACKAGE')}</span>
              </div>
              <span>{membershipDetails.clusterName && membershipDetails.clusterName}</span>
            </div>
            {membershipDetails.isLumpSumPlan && (
              <div className="card-info__info-field">
                <div className="card-info__info-field__title">
                  <img src={getIconUrl('Access')} alt="access" />
                  <span>{t('SS_MEMBERSHIP_CONTRACT_END_DATE')}</span>
                </div>
                <span>
                  {moment(membershipDetails.packages && membershipDetails.packages[0].endDate).format(
                    dateFormat.value.toUpperCase()
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="card-info__column">
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Plan')} alt="plan" />
                <span>{t('SS_MEMBERSHIP_PLAN')}</span>
              </div>
              <span>
                {membershipDetails.isLumpSumPlan ? t('SS_MEMBERSHIP_LUMP_SUM') : t('SS_MEMBERSHIP_RECURRING')}
              </span>
            </div>
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Addons')} alt="add-ons" />
                <span>{t('SS_MEMBERSHIP_ADDONS')}</span>
              </div>
              <span className="card-info__add-ons" onClick={() => showAddons(membershipDetails.addons)}>
                {membershipDetails.addons.length > 1
                  ? `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEMS')}`
                  : `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEM')}`}{' '}
              </span>
            </div>
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Access')} alt="access" />
                <span>{t('SS_MEMBERSHIP_ACCESS')}</span>
              </div>
              <span className="card-info__add-ons" onClick={() => showClubs()}>
                {countClub > 1 ? `${countClub} ${t('SS_MEMBERSHIP_CLUBS')}` : `${countClub} ${t('SS_MEMBERSHIP_CLUB')}`}{' '}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="card-info__info-group">
        <div className="card-info__column-first">
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Homeclub')} alt="home-club" />
              <span>{t('SS_MEMBERSHIP_HOME_CLUB')}</span>
            </div>
            <span>{membershipDetails.clubName && membershipDetails.clubName}</span>
          </div>
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Package')} alt="package" />
              <span>{t('SS_MEMBERSHIP_PACKAGE')}</span>
            </div>
            <span>{membershipDetails.clusterName && membershipDetails.clusterName}</span>
          </div>
        </div>
        <div className="card-info__column">
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Plan')} alt="plan" />
              <span>{t('SS_MEMBERSHIP_PLAN')}</span>
            </div>
            <span>{membershipDetails.isLumpSumPlan ? t('SS_MEMBERSHIP_LUMP_SUM') : t('SS_MEMBERSHIP_RECURRING')}</span>
          </div>
          {membershipDetails.isLumpSumPlan && (
            <div className="card-info__info-field">
              <div className="card-info__info-field__title">
                <img src={getIconUrl('Access')} alt="access" />
                <span>{t('SS_MEMBERSHIP_CONTRACT_END_DATE')}</span>
              </div>
              <span>
                {moment(membershipDetails.packages && membershipDetails.packages[0].endDate).format(
                  dateFormat.value.toUpperCase()
                )}
              </span>
            </div>
          )}
        </div>
        <div className="card-info__column">
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Addons')} alt="add-ons" />
              <span>{t('SS_MEMBERSHIP_ADDONS')}</span>
            </div>
            <span className="card-info__add-ons" onClick={() => showAddons(membershipDetails.addons)}>
              {membershipDetails.addons.length > 1
                ? `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEMS')}`
                : `${membershipDetails.addons.length} ${t('SS_MEMBERSHIP_ITEM')}`}{' '}
            </span>
          </div>
          <div className="card-info__info-field">
            <div className="card-info__info-field__title">
              <img src={getIconUrl('Access')} alt="access" />
              <span>{t('SS_MEMBERSHIP_ACCESS')}</span>
            </div>
            <span className="card-info__add-ons" onClick={() => showClubs()}>
              {countClub > 1 ? `${countClub} ${t('SS_MEMBERSHIP_CLUBS')}` : `${countClub} ${t('SS_MEMBERSHIP_CLUB')}`}{' '}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderFutureEvent = (futureEvents: any) => {
    return futureEvents.map((ev: any, index: Number) => {
      const startDate = moment(ev.startDate).format(dateFormat.value.toUpperCase());
      const createdDate = moment(ev.createdDate).format(dateFormat.value.toUpperCase());
      const actionDate = moment(ev.actionDate).format(dateFormat.value.toUpperCase());
      const endDate = moment(ev.endDate).format(dateFormat.value.toUpperCase());
      if (ev.eventType === CONSTANTS.FREEZE) {
        return (
          <div key={`freeze-${index}`}>
            <div className="card-event__event-field">
              <img src={getIconUrl('PrimaryDot')} alt="dot" />
              {ev.dateDiffDay !== 0 ? (
                <strong>
                  {t('SS_MEMBERSHIP_FREEZE_STARTS_ON')} <span>{startDate}</span> {t('SS_MEMBERSHIP_FOR')}{' '}
                  <span>
                    {ev.dateDiffMonth} {ev.dateDiffMonth !== 1 ? t('SS_MEMBERSHIP_MONTHS') : t('SS_MEMBERSHIP_MONTH')}
                  </span>
                  .
                </strong>
              ) : (
                <strong>
                  {t('SS_MEMBERSHIP_FREEZE_STARTS_ON')} <span>{startDate}</span> {t('SS_MEMBERSHIP_ENDS_ON')}{' '}
                  <span>{endDate}</span>.
                </strong>
              )}
            </div>
            {index !== futureEvents.length - 1 && <hr className="card-event__separate-line" />}
          </div>
        );
      } else if (ev.eventType === CONSTANTS.CANCEL) {
        return (
          <div key={`cancel-${index}`}>
            <div key={`cancel-${index}`} className="card-event__event-field">
              <img src={getIconUrl('PrimaryDot')} alt="dot" />
              <strong>
                {t('SS_MEMBERSHIP_CANCEL_CREATED_ON')} <span>{createdDate}</span>.{' '}
                {t('SS_MEMBERSHIP_AND_WILL_BE_ACTIONED')} <span>{actionDate}</span>.
              </strong>
            </div>
            {index !== futureEvents.length - 1 && <hr className="card-event__separate-line" />}
          </div>
        );
      } else if (ev.eventType === CONSTANTS.TRANSFER) {
        const renderFutureEvents = () => {
          if (!ev.planName && !ev.clubName) {
            return <div key={`transfer-${index}`}>{t('SS_MEMBERSHIP_CHANGE_MEMBERSHIP_AWAIT')}</div>;
          } else {
            return (
              <div key={`transfer-${index}`}>
                {t('SS_MEMBERSHIP_WILL_BE_CHANGED_ON')} <span>{actionDate}</span>
                <br />
              </div>
            );
          }
        };
        return (
          <div key={`transfer-${index}`}>
            <div className="card-event__event-field">
              <img src={getIconUrl('PrimaryDot')} alt="dot" />
              <strong>{renderFutureEvents()}</strong>
            </div>
            {index !== futureEvents.length - 1 && <hr className="card-event__separate-line" />}
          </div>
        );
      } else {
        return (
          <div key={`change-${index}`}>
            <div className="card-event__event-field">
              <img src={getIconUrl('PrimaryDot')} alt="dot" />
              <strong>
                {t('SS_MEMBERSHIP_HOME_CLUB_CHANGE_TO')} <span>{ev.newClubName}</span> {t('SS_MEMBERSHIP_ON')}{' '}
                <span>{actionDate}</span>
              </strong>
            </div>
            {index !== futureEvents.length - 1 && <hr className="card-event__separate-line" />}
          </div>
        );
      }
    });
  };
  return (
    <div className="membership">
      {showThankUpgrade ? (
        <UpgradeThankyouSummary
          setShowThankUpgrade={setShowThankUpgrade}
          showThankUpgrade={showThankUpgrade}
        ></UpgradeThankyouSummary>
      ) : (
        <>
          {(!isMobile || enablePlan) && (
            <Card className={`${isMobile ? 'accordion-card' : ''} card-info`}>
              {!isMobile && <p className="card-info__title">{t('SS_MEMBERSHIP_INFORMATION')}</p>}
              {renderMemberInfo()}
            </Card>
          )}
          {isMobile && (
            <div
              className={`expand-icon ${enablePlan ? 'rotate-expand' : ''}`}
              onClick={() => setEnablePlan(!enablePlan)}
            >
              <img src={getIconUrl('Expand')} alt="expand-icon" />
            </div>
          )}
          {membershipDetails.futureEvents && membershipDetails.futureEvents.length > 0 && (
            <Card className="card-event">
              <p>{t('SS_MEMBERSHIP_FUTURE_EVENTS')}</p>
              <div className="card-event__event-group">{renderFutureEvent(membershipDetails.futureEvents)}</div>
            </Card>
          )}
          <Addon
            addons={addons}
            setToggleMenu={setToggleMenu}
            isAllowAddAddon={isAllowAddAddon}
            loadingAddons={loadingAddons}
            clubId={membershipDetails.clubId}
            planId={membershipDetails.planId}
          />
          {isAllowUpgradeMembership && (
            <div className="addons">
              <button className="addons__heading" onClick={handleOnClickUpgradeMembership}>
                <span className="addons__crown-icon">
                  <CrownIcon />
                </span>
                <b>{t('SS_UPGRADE_MEMBERSHIP_TITLE')}</b>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MembershipInfo;
