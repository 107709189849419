import PERSONAL_CONSTANTS from "constants/personalConstant";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "redux/configureStore";
import { ConvertExpiryDate } from "utility/checkExpiredCard";
import { convertPaymentMethod } from "utility/payment";

type AdyenPaymentInfoProps = {
  creditImg: string;
};

const AdyenPaymentInfo = (props: AdyenPaymentInfoProps) => {
  const { creditImg } = props;
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const primary = membershipDetails.payment?.primary;
  const isPrimaryExist = primary?.paymentMethod;
  const { t } = useTranslation();

  return (
    <>
      <div className="payment__method-name">
        <img src={creditImg} alt="card-payment" />
        <b>
          {convertPaymentMethod(
            (isPrimaryExist && primary.paymentMethod) ||
              PERSONAL_CONSTANTS.CARD,
          )}
        </b>
      </div>
      {isPrimaryExist &&
        convertPaymentMethod(primary.paymentMethod) ===
          PERSONAL_CONSTANTS.CARD && <hr />}
      {isPrimaryExist &&
        convertPaymentMethod(primary.paymentMethod) ===
          PERSONAL_CONSTANTS.CARD && (
          <div className="payment__method-info">
            <div className="payment__method-suspended">
              <strong>{t("SS_PAYMENT_CARD_INFORMATION")}</strong>
            </div>
            <b>
              {t("SS_PAYMENT_CARD_ENDING")}{" "}
              {primary &&
                primary.accountNumber?.substring(
                  primary.accountNumber.length - 4,
                )}
            </b>
            <b>
              {t("SS_PAYMENT_EXPIRES")}{" "}
              {primary?.expiryDate &&
                ConvertExpiryDate(primary.expiryDate, true)}
            </b>
          </div>
        )}
      {isPrimaryExist &&
        primary.paymentMethod === PERSONAL_CONSTANTS.DIRECT_DEBIT && (
          <div>
            <div className="payment__method-info">
              <strong>{t("SS_PAYMENT_ACCOUNT_NAME")}</strong>
              <b>{primary.accountName}</b>
            </div>
            <div className="payment__method-info">
              <strong>{t("SS_PAYMENT_ACCOUNT_NUMBER")}</strong>
              <b>
                ******
                {primary.accountNumber.substring(
                  primary.accountNumber.length - 4,
                )}
              </b>
            </div>
          </div>
        )}
    </>
  );
};

export default AdyenPaymentInfo;
