import { useTranslation } from "react-i18next";

import { Checkbox, FormControlLabel } from "@material-ui/core"; 

import NumberFormat from "react-number-format";
import format from "number-format.js";

import usePaymentHandler from "./hooks/usePaymentHandler";

import EzyPayFee from "components/EzyPayFee";

import { Alert } from "@material-ui/lab";

import { AddonInterface } from "components/Addon/types";

import "./make-ezypay.scss";

type MakePaymentEzyPayProps = {
  amount?: number;
  showTitle?: boolean;
  showAmount?: boolean;
  addons?: AddonInterface[];
};

const MakePaymentEzyPay = (props: MakePaymentEzyPayProps) => {
  const { 
    amount = 0, 
    showTitle = false, 
    showAmount = true, 
    addons = [] 
  } = props;
  
  const { t } = useTranslation();
  const {
    agreement,
    currencyCode,
    currencyFormat,
    amountPayment,
    showError,
    membershipDetails,
    showSaveNewPaymentMethod,
    totalRecurringFee,
    handleOnChangeAgreement,
    handleSubmit,
    setAmountPayment,
  } = usePaymentHandler(amount, addons);
  
  return (
    <div className="ezypay-make-payment">
      {showTitle && (
        <p className="ezypay-make-payment__title">
          {t("SS_PAYMENT_NO_ARREARS")}
        </p>
      )}
      {showAmount && (
        <div className="ezypay-make-payment__input ">
          <label>{t("SS_PAY_ARREARS_PAYMENT_AMOUNT")}</label>
          <NumberFormat
            min={0}
            className="make-payment__input"
            value={amountPayment}
            onChange={(event: any) => setAmountPayment(event.target.value)}
          />
        </div>
      )}
      <div className="ezypay-make-payment__term">
        <FormControlLabel
          className="ezypay-make-payment__term-link"
          control={
            <Checkbox
              name="term"
              onChange={handleOnChangeAgreement}
              checked={agreement.term}
            />
          }
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("SS_PAYMENT_TERM_AND_CONDITIONS", {
                    termsConditions:
                      membershipDetails?.endPoints?.termsAndConditionUrl || "#",
                  }),
                }}
              />
              <span className="is-required">*</span>
            </>
          }
        />
        {showError.missingAgreement && (
          <span className="is-required">
            {t("SS_PAYMENT_TERM_AND_CONDITIONS_ERROR")}
          </span>
        )}
        {agreement.savePayment && (
          <EzyPayFee
            loading={<span className="loading-fee">{t("LOADING_FEE")}</span>}
          />
        )}
      </div>
      {showSaveNewPaymentMethod && (
        <FormControlLabel
          className="ezypay-make-payment__save-payment"
          control={
            <Checkbox
              name="savePayment"
              checked={agreement.savePayment}
              onChange={handleOnChangeAgreement}
            />
          }
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("SAVE_PAYMENT_METHOD", {
                    currency: currencyCode,
                    amount: format(currencyFormat!, totalRecurringFee),
                  }),
                }}
              />
            </>
          }
        />
      )}
      {showError.errorPayment && (
        <Alert
          icon={false}
          variant="outlined"
          className="error-wrapper"
          severity="error"
        >
          {t("ERROR_PAYMENT")}
        </Alert>
      )}
      <button
        className="btn btn-primary btn--aside btn-effect"
        onClick={handleSubmit}
      >
        {t("NEXT")}
      </button>
    </div>
  );
};

export default MakePaymentEzyPay;
