import { PAYMENT_METHOD_TYPES } from "constants/paymentConstants";
import PERSONAL_CONSTANTS from "constants/personalConstant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getPaymentMethodFail,
  updateEzyPayInfo,
} from "redux/actionCreators/payment";
import { useTypedSelector } from "redux/configureStore";
import { selectConfigByKey } from "selectors/config";
import { getEzyToken } from "services/payment";
import EzyPayHelper from "utility/ezyPay";

const { EZYPAY_FRAME_URL, EZYPAY_COUNTRY } = window._env;

type UseEzyPayFrameProps = {
  paymentMethodId: string;
  agreeRef: any;
};

const useEzyPayFrame = ({ paymentMethodId, agreeRef }: UseEzyPayFrameProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [isLoadingIFrame, setIsLoadingIFrame] = useState(true);
  const [messageData, setMessageData] = useState<any>();
  const payToEnable = useTypedSelector((state) =>
    selectConfigByKey(state, PERSONAL_CONSTANTS.EZYPAY_PAY_TO),
  );
  const mandateTypeID = useTypedSelector((state) =>
    selectConfigByKey(state, PERSONAL_CONSTANTS.EZYPAY_MANDATE_TYPE),
  );

  const loadToken = useCallback(async () => {
    try {
      const response = await getEzyToken();
      setToken(response.access_token);
    } catch (error) {
      dispatch(getPaymentMethodFail({ error: t("ERROR_TOKEN") }));
    }
  }, []);

  useEffect(() => {
    loadToken();
  }, [loadToken]);

  const onResized = (data: any) => {
    if (data.type !== "init") {
      setMessageData({
        height: Number(data.height) + 50,
        width: data.width,
      });
    } else {
      setMessageData(data);
    }
  };

  const refreshIframe = (force?: boolean) => {
    if (agreeRef?.current?.checked && !force) {
      return;
    }
    setToken("");
    loadToken();
  };

  const iframeSrc = useMemo(() => {
    if (!token) {
      return "";
    }
    setIsLoadingIFrame(true);
    let originalSrc = `${EZYPAY_FRAME_URL}/paymentmethod/embed?token=${token}&countryCode=${EZYPAY_COUNTRY}`;
    const payTo = payToEnable ? ",PAYTO" : "";
    if (paymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD) {
      originalSrc += `&paymentMethods=VISA,MASTERCARD,AMEX`;
      return originalSrc;
    } else {
      originalSrc += `&paymentMethods=BANK${payTo}&mandateTypeId=${mandateTypeID}`;
      return originalSrc;
    }
  }, [paymentMethodId, token, payToEnable, mandateTypeID]);

  const onLoadIframe = () => {
    setIsLoadingIFrame(false);
  };

  const isIframeAdjustHeight = useMemo(() => {
    return Number(messageData?.height) === 0;
  }, [messageData]);

  const retrieveToken = (event: any, isAgreesTerm: boolean) => {
    const receiver = document.getElementById(
      "ezypay-iframe",
    ) as HTMLIFrameElement;
    if (receiver.contentWindow) {
      const ezPayHandler = new EzyPayHelper(receiver.contentWindow);
      ezPayHandler
        .sendPayment(event, "create", isAgreesTerm, refreshIframe)
        .then((response: any) => {
          if (agreeRef?.current?.checked) {
            dispatch(updateEzyPayInfo(response));
          }
        });
    }
  };

  return {
    isIframeAdjustHeight,
    token,
    messageData,
    iframeSrc,
    isLoadingIFrame,
    retrieveToken,
    onResized,
    onLoadIframe,
    refreshIframe,
  };
};

export default useEzyPayFrame;
