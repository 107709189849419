import { createSelector } from "reselect";
import { RootState } from "redux/reducers";

import { PaymentPrimary } from "types/IPayment";

export const selectMembershipDetails = (state: RootState) =>
  state.membership.membershipDetails;

export const selectPaymentPrimary = createSelector(
  selectMembershipDetails,
  (membershipDetails) => membershipDetails.payment.primary as PaymentPrimary,
);
