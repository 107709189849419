import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import { CircularProgress } from "@material-ui/core";

export const showSuccessMessage = (message: string) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const showLoading = () => {
  Swal.fire({
    customClass: {
      container: "loading-container",
    },
    html: ReactDOMServer.renderToString(
      <CircularProgress className="loading" />,
    ),
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
  });
};
