import {  ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEzyFee } from "services/payment";
import { EzyPayFee as EzyPayFeeModel } from "types/IPayment";

import "./ezypay-fee.scss";
import { useTypedSelector } from "redux/configureStore";

type EzyPayFeeProps = {
  loading?: ReactNode;
};

const EzyPayFee = (props: EzyPayFeeProps) => {
  const { loading = <></> } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [ezyPayFees, setEzyPayFees] = useState<EzyPayFeeModel[] | null>(null);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const loadEzyPayFee = async () => {
      try {
        const ezyPayFee = await getEzyFee();
        setEzyPayFees(ezyPayFee);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (!ezyPayFees?.length) {
      loadEzyPayFee();
    }
  }, [ezyPayFees]);

  if (ezyPayFees?.length === 0 || isLoading) {
    return <>{loading}</>;
  }

  return (
    <div className="ezypay-fee">
      <span>{t("EZY_PAY_DESCRIPTION")}</span>
      {error ? (
        <span className="ezypay-fee--error">{t("EZYPAY_FEE_ERROR")}</span>
      ) : (
        <ul className="ezypay-fee__description">
          {(ezyPayFees || []).map((ezyPayFee) => (
            <li key={ezyPayFee.description}>
              {ezyPayFee.description} : {ezyPayFee.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default EzyPayFee;
