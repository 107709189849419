import React, { useState, useEffect } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import i18n from 'translations/i18n';
import { getAdyenJSONCode, getLanguagesService } from 'services';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import emitNotify from 'utility/emitNotify';
import { useTypedSelector } from 'redux/configureStore';

const LanguageSwitching = () => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem(PERSONAL_CONSTANTS.LANGUAGE) || PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT
  );
  const [isGettingLanguage, setGettingLanguage] = useState(true);
  const [languageList, setLanguageList] = React.useState<{ languageCode: string; languageName: string }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isEzyPayDisabled } = useTypedSelector((state) => state.payment);

  useEffect(() => {
    i18n.on('languageChanged', (lng: string) => {
      setCurrentLanguage(i18n.language);
      if (!i18n.store.data[lng] && i18n.isInitialized) {
        emitNotify(enqueueSnackbar, t('SS_CHANGE_LANGUAGE_TITLE'), t('SS_CHANGE_LANGUAGE_ERROR'), 'error');
        i18n.changeLanguage(PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT);
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getLanguagesService();
        setLanguageList(data || []);
        setGettingLanguage(false);
      } catch {
        setGettingLanguage(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isEzyPayDisabled) {
      getAdyenJSONCode(_env.COUNTRY_CODE, i18n.language).then(({ data }) => {
        if (!i18n.hasResourceBundle(`${i18n.language}-adyen`, 'translations')) {
          i18n.addResourceBundle(`${i18n.language}-adyen`, 'translations', data, true, false);
        }
      });
    }
  }, [isEzyPayDisabled]);

  const onSwitchLanguage = async (event: any) => {
    const lng = event.target?.value;
    setCurrentLanguage(lng);
    if (!i18n.hasResourceBundle(`${lng}-adyen`, 'translations')) {
      try {
        const { data } = await getAdyenJSONCode(_env.COUNTRY_CODE, lng);
        i18n.addResourceBundle(`${lng}-adyen`, 'translations', data, true, false);
      } catch (err: any) {
        console.log('Warning: Load resource bundle failed', err);
      }
    }
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (!isGettingLanguage) {
      const isExisted = languageList.find((item) => item && item.languageCode === currentLanguage);
      if (!isExisted) {
        i18n.changeLanguage(PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT);
      }
    }
  }, [isGettingLanguage, languageList]);

  return (
    <Select
      label="languages-switching"
      variant="outlined"
      MenuProps={{
        className: 'languages-switching',
        classes: { list: 'languages-switching__list', paper: 'languages-switching__paper' },
      }}
      value={currentLanguage}
      onChange={onSwitchLanguage}
    >
      {languageList.map((item) =>
        item ? (
          <MenuItem value={item.languageCode} key={item.languageCode}>
            <span>{item.languageName}</span>
          </MenuItem>
        ) : null
      )}
    </Select>
  );
};

export default LanguageSwitching;
