import { Checkbox, FormControlLabel } from "@material-ui/core";
import PERSONAL_CONSTANTS from "constants/personalConstant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "redux/configureStore";
import { useDeviceCheck } from "utility/screenDetect";

import format from "number-format.js";

import { PAYMENT_ERRORS } from "constants/paymentConstants";

import '@adyen/adyen-web/dist/adyen.css';

const AdyenPayment = (props: any) => {
  const { error } = props;
  const { t, i18n } = useTranslation();
  const [, , isMobile] = useDeviceCheck();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const [isCheckAffirmativeSubcriptionTerms, setIsCheckAffirmativeSubcriptionTerms] = useState(false);
  const { membershipDetails } = useTypedSelector(state => state.membership);
  const handleCheckAffirmativeSubscriptionTerms = (e: any) => {
    setIsCheckAffirmativeSubcriptionTerms(e.target.checked);
  };
  const { regexs } = useTypedSelector((state) => state.personal);
  const { totalRecurringFee } = useTypedSelector((state) => state.membership);
 
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  useEffect(() => {
    const submitPaymentButton = document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement;
    if (submitPaymentButton && totalRecurringFee > 0) {
      submitPaymentButton.disabled = !isCheckAffirmativeSubcriptionTerms;
    }
  }, [isCheckAffirmativeSubcriptionTerms, totalRecurringFee]);

  const renderAffirmativeSubcriptionTerms = () =>
    totalRecurringFee > 0 ? (
      <div className="edit-payment__affirmative-subscription-terms">
        <FormControlLabel
          control={
            <Checkbox
              name="checkAffirmativeSubcriptionTerms"
              onChange={handleCheckAffirmativeSubscriptionTerms}
              checked={isCheckAffirmativeSubcriptionTerms}
            />
          }
          label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_MAKE_AND_EDIT_PAYMENT', {
            currency: membershipDetails.currencyCode,
            recurringFeeAmount: format(currencyFormat.value, totalRecurringFee),
          })}
        />
        {!isCheckAffirmativeSubcriptionTerms && (
          <span className="reminder">{t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_REMIND_EDIT')}</span>
        )}
      </div>
    ) : (
      <></>
    );

  const renderError = () => {
    switch (error) {
      case PAYMENT_ERRORS.NETWORK_ERROR:
        return t('CONNECTION_INTERRUPTED');
      case PAYMENT_ERRORS.PAYMENT_IS_IN_PROGRESS:
        return t('PAYMENT_IS_LOCKED');
      default:
        return tAdyen(error);
    }
  };
  const renderErrorByScreen = () => {
    if(!isMobile) { 
        return (
          <div className="payment-title">
            <p>{t("SS_PAYMENT_INFORMATION")}</p>
            {error && <div className="payment-error">{renderError()}</div>}
          </div>
        );
    }
    return error && <div className="payment-error">{renderError()}</div>;
  };
  
  return (
    <div className="edit-payment">
      {renderErrorByScreen()}
      {renderAffirmativeSubcriptionTerms()}
      <div id="dropin-container1"></div>
    </div>
  );
};

export default AdyenPayment;