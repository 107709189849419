import { RootState } from "redux/reducers";
import { createSelector } from "reselect";

export const selectPaymentMethodTypes = (state: RootState) =>
  state.membership.paymentMethodTypes;

export const selectPaymentForEzyPay = createSelector(
  selectPaymentMethodTypes,
  (paymentMethodTypes) => {
    return paymentMethodTypes.filter(
      (payment: any) =>
        payment.planMethodCode === "PR" &&
        payment.paymentMethodTypeCode !== "NO",
    );
  },
);
