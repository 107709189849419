import { RootState } from "redux/reducers";
import { createSelector } from "reselect";
import { Config } from "types/Config";

export const selectConfig = (state: RootState) => state.personal.regexs;

export const selectConfigByKey = createSelector(
  [selectConfig, (_, key) => key],
  (configs: Config[], key: string) => {
    const configFound = configs.find((config) => config.key === key);
    return configFound?.value || "";
  },
);
