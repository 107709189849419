import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  TableSortLabel,
  Button,
  Select,
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';

import { useDeviceCheck } from 'utility/screenDetect';
import { getIconUrl } from 'utility/helpers';
import moment from 'moment';
import { useTypedSelector } from 'redux/configureStore';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { Pagination } from '@material-ui/lab';
import { getDescription } from 'utility/inbox';

interface ListActionsProps {
  actions: never[];
  loadingAction: boolean;
}
interface ActionsTableProps {
  actions: never[];
  isOrderByDescending: boolean;
  sortHandler: () => void;
  dateFormat: string;
  paymentActions: string[];
  lokalisePaymentDetailsDesc: string[];
}

const ActionsTableMobile = (props: any) => {
  const { action, dateFormat, paymentActions, lokalisePaymentDetailsDesc } = props;
  const { t, i18n } = useTranslation();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const [openAddon, setOpenAddon] = useState(false);
  const formatDate = (date: string) => moment(date).format(dateFormat);
  const formatDateTime = (date: string) => moment(date).format(`${dateFormat} HH:mm`);
  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={
        <Accordion
          classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
          expanded={false}
          onChange={() => { }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ root: 'addons-root', content: 'addons-summary' }}
          ></AccordionSummary>
        </Accordion>
      }
    >
      <Accordion
        classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
        expanded={openAddon}
        onChange={(e, expanded) => setOpenAddon(expanded)}
      >
        <AccordionSummary
          expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
          classes={{ root: 'addons-root', content: 'addons-summary' }}
        >
          <span className={!action.isSuccess ? 'cell--alert' : ''}>
            {t(action.action)} {!action.isSuccess && <img src={getIconUrl('Alert')} alt="alert" />}
          </span>
          <span>{formatDateTime(action.date)} </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="addon-details__detail-group">
            <div className="addon-details__detail-field">
              <span>
                {(paymentActions.includes(action.action) &&
                  !action.isSuccess &&
                  !lokalisePaymentDetailsDesc.includes(action.descriptionKey) &&
                  tAdyen(action.descriptionKey)) ||
                  t(action.descriptionKey, {
                    numberName: action.numberName,
                    addressLine1: action.addressLine1,
                    addressLine2: action.addressLine2,
                    cityProvice: action.city,
                    postCode: action.postalCode,
                    country: action.country,
                    homeNumber: action.homephoneNumber || t('SS.Description.NONE_VALUE'),
                    mobileNumber: action.mobileNumber || t('SS.Description.NONE_VALUE'),
                    firstName: action.firstName,
                    lastName: action.lastName,
                    emergencyContactName: action.emergencyContactName || t('SS.Description.NONE_VALUE'),
                    emergencyContactNumber: action.emergencyContactPhone || t('SS.Description.NONE_VALUE'),
                    languageName: action.languageName,
                    currencyCode: action.currencyCode,
                    paidAmount: action.paidAmount,
                    accountLastFour: getDescription(action),
                    addonName: action.addonName,
                    addonStartDate: formatDate(action.addonStartDate),
                    addonEndDate: formatDate(action.addonEndDate),
                    packageName: action.packageName,
                    packageStartDate: formatDate(action.packageStartDate),
                  })}
              </span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </LazyLoad>
  );
};

const ActionsTable = (props: ActionsTableProps) => {
  const { actions, isOrderByDescending, sortHandler, dateFormat, paymentActions, lokalisePaymentDetailsDesc } = props;
  const { t, i18n } = useTranslation();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const formatDate = (date: string) => moment(date).format(dateFormat);
  const formatTime = (date: string) => moment(date).format('HH:mm');

  return (
    <TableContainer component={Paper} classes={{ root: 'table-container' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" width="15%">
              <TableSortLabel active={true} direction={isOrderByDescending ? 'desc' : 'asc'} onClick={sortHandler}>
                {t('SS_INBOX_DATE')}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" width="25%">
              {t('SS_INBOX_ACTION')}
            </TableCell>
            <TableCell align="center" width="60%">
              {t('SS_INBOX_DESCRIPTION')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action: any) => {
            return (
              action && (
                <TableRow key={`action-${action.rowKey}`} className="table-row">
                  <TableCell align="left">
                    {formatDate(action.date)} <br /> {formatTime(action.date)}
                  </TableCell>
                  <TableCell align="center" className={!action.isSuccess ? 'cell--alert' : ''}>
                    {t(action.action)} {!action.isSuccess && <img src={getIconUrl('Alert')} alt="alert" />}
                  </TableCell>
                  <TableCell align="left">
                    {(paymentActions.includes(action.action) &&
                      !action.isSuccess &&
                      !lokalisePaymentDetailsDesc.includes(action.descriptionKey) &&
                      tAdyen(action.descriptionKey)) ||
                      t(action.descriptionKey, {
                        numberName: action.numberName,
                        addressLine1: action.addressLine1,
                        addressLine2: action.addressLine2,
                        cityProvice: action.city,
                        postCode: action.postalCode,
                        country: action.country,
                        homeNumber: action.homephoneNumber || t('SS.Description.NONE_VALUE'),
                        mobileNumber: action.mobileNumber || t('SS.Description.NONE_VALUE'),
                        firstName: action.firstName,
                        lastName: action.lastName,
                        emergencyContactName: action.emergencyContactName || t('SS.Description.NONE_VALUE'),
                        emergencyContactNumber: action.emergencyContactPhone || t('SS.Description.NONE_VALUE'),
                        languageName: action.languageName,
                        currencyCode: action.currencyCode,
                        paidAmount: action.paidAmount,
                        accountLastFour: getDescription(action),
                        addonName: action.addonName,
                        addonStartDate: formatDate(action.addonStartDate),
                        addonEndDate: formatDate(action.addonEndDate),
                        packageName: action.packageName,
                        packageStartDate: formatDate(action.packageStartDate),
                      })}
                  </TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const ListActions = (props: ListActionsProps) => {
  const { actions, loadingAction } = props;
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const [, , isMobile] = useDeviceCheck();
  const { t } = useTranslation();
  const paymentActions = ['SS.Action.ChangePaymentDetail', 'SS.Action.MakePayment'];
  const lokalisePaymentDetailsDesc = [
    'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_DEBIT_METHOD',
    'ONLINE_PAYMENT_METHOD_IS_EMPTY',
    'ONLINE_PAYMENT_METHOD_IS_NOT_ALLOWED',
    'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_CREDIT_METHOD',
  ];
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 1,
    isOrderByDescending: true,
  });
  const handlePagination = (newCriteria: { pageIndex?: number; pageSize?: number; isOrderByDescending?: boolean }) => {
    setPagination({
      ...pagination,
      pageIndex: newCriteria.pageIndex ?? pagination.pageIndex,
      pageSize: newCriteria.pageSize ?? pagination.pageSize,
      isOrderByDescending: newCriteria.isOrderByDescending ?? pagination.isOrderByDescending,
    });
  };
  const [pagingActions, setPagingActions] = useState(actions);

  useEffect(() => {
    setPagination({
      pageSize: pagination.pageSize,
      pageIndex: 1,
      isOrderByDescending: true,
    });
    setPagingActions(actions.slice(0, pagination.pageSize));
  }, [actions]);

  const sortHandler = () => {
    handlePagination({ isOrderByDescending: !pagination.isOrderByDescending, pageIndex: 1 });
    actions.sort((a, b) => {
      if (b['date'] < a['date']) {
        return !pagination.isOrderByDescending ? -1 : 1;
      }
      if (b['date'] > a['date']) {
        return !pagination.isOrderByDescending ? 1 : -1;
      }
      return 0;
    });
    setPagingActions(actions.slice(0, pagination.pageSize));
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    const startPoint = (newPage - 1) * pagination.pageSize;
    handlePagination({ pageIndex: newPage });
    setPagingActions(actions.slice(startPoint, startPoint + pagination.pageSize));
  };

  const handleChangeRowsPerPage = (event: any, _: any) => {
    handlePagination({ pageSize: +event?.target.value, pageIndex: 1 });
    setPagingActions(actions.slice(0, +event?.target.value));
  };

  const renderActionsTable = (dateFormat: string) => {
    return isMobile ? (
      <div className="list-actions__mobile">
        <Button className="list-actions__mobile__sort" onClick={sortHandler}>
          {t('SS_DATE')}
          <img
            src={getIconUrl(pagination.isOrderByDescending ? 'SortCalendarDescending' : 'SortCalendarAscending')}
            alt="sort-icon"
          ></img>
        </Button>
        {pagingActions.map(
          (action: any) =>
            action && (
              <ActionsTableMobile
                key={`action-${action.rowKey}`}
                paymentActions={paymentActions}
                lokalisePaymentDetailsDesc={lokalisePaymentDetailsDesc}
                action={action}
                dateFormat={dateFormat}
              />
            )
        )}
      </div>
    ) : (
      <ActionsTable
        actions={pagingActions}
        isOrderByDescending={pagination.isOrderByDescending}
        sortHandler={sortHandler}
        dateFormat={dateFormat}
        paymentActions={paymentActions}
        lokalisePaymentDetailsDesc={lokalisePaymentDetailsDesc}
      />
    );
  };

  return (
    <div className="list-actions-container">
      <div className="list-actions__table">
        {loadingAction ? (
          <div className="list-actions__loading">
            <CircularProgress className="loading-table" />
          </div>
        ) : pagingActions && pagingActions.length > 0 ? (
          renderActionsTable(dateFormat.value.toUpperCase())
        ) : (
          <p className="list-actions__no-actions">{t('SS_INBOX_NO_ACTIONS')}</p>
        )}
      </div>
      {actions.length > 0 && (
        <div className="list-actions__pagination">
          <div className="list-actions__pagination--rows-per-page">
            <span>{t('SS_INBOX_ROWS_PER_PAGE')}</span>
            <Select native value={pagination.pageSize} onChange={handleChangeRowsPerPage}>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          </div>
          <Pagination
            count={Math.ceil(actions.length / pagination.pageSize)}
            shape="rounded"
            defaultPage={1}
            page={pagination.pageIndex}
            onChange={handleChangePage}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export default ListActions;
