import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { IPersonalBody, IMarketingPreferences } from 'types/IPersonal';
import { personalActionTypes as actionTypes } from '../actionTypes';
import { personalActions as actionCreators } from '../actionCreators';

import {
  editPersonalInfoService,
  getConfigService,
  getPersonalFieldsService,
  updateMarketingPreferencesService,
} from 'services';

function* editPersonalInfo({ memberId, body }: { type: string; memberId: number; body: IPersonalBody }) {
  try {
    const { data } = yield call(editPersonalInfoService, memberId, body);
    if (data.isSuccess) {
      yield put(actionCreators.editPersonalInfoSuccess(data.isSuccess));
    } else {
      yield put(actionCreators.editPersonalInfoFail({ error: data.message }));
    }
  } catch (error: any) { }
}

function* getConfig() {
  try {
    const { data } = yield call(getConfigService);
    if (data.data) {
      yield put(actionCreators.getConfigSuccess(data.data));
    } else {
      yield put(actionCreators.getConfigFail({ error: data.message }));
    }
  } catch (error: any) { }
}

function* getPersonalFields({ featureCode }: { type: string; featureCode: string }) {
  try {
    const { data } = yield call(getPersonalFieldsService, featureCode);
    if (data) {
      yield put(actionCreators.getPersonalFieldsSuccess(data));
    } else {
      yield put(actionCreators.getPersonalFieldsFail({ error: data.message }));
    }
  } catch (error: any) { }
}

function* updateMarketingPreferences({
  memberId,
  body,
}: {
  type: string;
  memberId: number;
  body: IMarketingPreferences;
}) {
  try {
    const { data } = yield call(updateMarketingPreferencesService, memberId, body);
    if (data.isSuccess) {
      yield put(actionCreators.updateMarketingPreferencesSuccess(data.isSuccess));
    } else {
      yield put(actionCreators.updateMarketingPreferencesFail({ error: data.message }));
    }
  } catch (error: any) { }
}

function* watchPersonal() {
  yield takeLatest(actionTypes.EDIT_PERSONAL_INFO, editPersonalInfo);
  yield takeLatest(actionTypes.GET_CONFIG, getConfig);
  yield takeLatest(actionTypes.GET_PERSONAL_FIELDS, getPersonalFields);
  yield takeLatest(actionTypes.UPDATE_MARKETING_PREFERENCES, updateMarketingPreferences);
}

export default function* personalSaga() {
  yield all([fork(watchPersonal)]);
}
