import { EzyPayInfo } from 'types/EzyPay/EzyPayInfo';
import { IArrearsResponse } from 'types/IPayment';

export const GET_PAYMENT_METHOD = 'payments/GET_PAYMENT_METHOD';
export interface GetPaymentMethodAction {
  type: typeof GET_PAYMENT_METHOD;
  memberId: number;
}

export const GET_PAYMENT_METHOD_SUCCESS = 'payments/GET_PAYMENT_METHOD_SUCCESS';
export interface GetPaymentMethodSucessAction {
  type: typeof GET_PAYMENT_METHOD_SUCCESS;
  paymentMethodsResponse: any;
}

export const GET_PAYMENT_METHOD_FAIL = 'payments/GET_PAYMENT_METHOD_FAIL';
export interface GetPaymentMethodFailAction {
  type: typeof GET_PAYMENT_METHOD_FAIL;
  error: any;
}

export const EDIT_PAYMENT = 'payments/EDIT_PAYMENT';
export interface EditPaymentAction {
  type: typeof EDIT_PAYMENT;
  membershipDetails: string;
  dataState: any;
  dropinForm: any;
}

export const EDIT_PAYMENT_SUCCESS = 'payments/EDIT_PAYMENT_SUCCESS';
export interface EditPaymentSucessAction {
  type: typeof EDIT_PAYMENT_SUCCESS;
  paymentResponse: any;
}

export const EDIT_PAYMENT_FAIL = 'payments/EDIT_PAYMENT_FAIL';
export interface EditPaymentFailAction {
  type: typeof EDIT_PAYMENT_FAIL;
  error: any;
}

export const PAY_WITH_REGISTER_CARD = 'payments/PAY_WITH_REGISTER_CARD';
export interface PayWithRegisterCardAction {
  type: typeof PAY_WITH_REGISTER_CARD;
  amount: number;
  addonIds?: [number];
  upgradedMembership?: any;
}

export const PAY_WITH_REGISTER_CARD_SUCCESS = 'payments/PAY_WITH_REGISTER_CARD_SUCCESS';
export interface PayWithRegisterCardSucessAction {
  type: typeof PAY_WITH_REGISTER_CARD_SUCCESS;
  paymentResponse: any;
}

export const PAY_WITH_REGISTER_CARD_FAIL = 'payments/PAY_WITH_REGISTER_CARD_FAIL';
export interface PayWithRegisterCardFailAction {
  type: typeof PAY_WITH_REGISTER_CARD_FAIL;
  error: any;
}

export const DONE_UPDATE_PAYMENT = 'payments/DONE_UPDATE_PAYMENT';
export interface DoneUpdatePaymentAction {
  type: typeof DONE_UPDATE_PAYMENT;
}

export const DONE_PAY_WITH_REGISTER_CARD = 'payments/DONE_PAY_WITH_REGISTER_CARD';
export interface DonePayWithRegisterCardAction {
  type: typeof DONE_PAY_WITH_REGISTER_CARD;
  purpose: string;
}

export const CLEAR_PAYMENT_SUCCESS = 'payments/CLEAR_PAYMENT_SUCCESS';
export interface ClearPaymentAction {
  type: typeof CLEAR_PAYMENT_SUCCESS;
  clearResponse?: boolean;
}

export const VERIFY_AFTER_THREEDS_EDIT_PAYMENT = 'payments/VERIFY_AFTER_THREEDS_EDIT_PAYMENT';
export interface VerifyAfterThreeDSEditPaymentAction {
  type: typeof VERIFY_AFTER_THREEDS_EDIT_PAYMENT;
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: {
      'threeds2.fingerprint'?: string;
      'threeds2.challengeResult'?: string;
    };
  };
}

export const VERIFY_AFTER_THREEDS = 'payments/VERIFY_AFTER_THREEDS';
export interface VerifyAfterThreeDSAction {
  type: typeof VERIFY_AFTER_THREEDS;
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: {
      'threeds2.fingerprint'?: string;
      'threeds2.challengeResult'?: string;
    };
  };
  isVerifyForPayment?: boolean;
  addonIds?: [number];
  upgradedMembership?: any;
}

export const VERIFY_AFTER_THREEDS_REGISTER_CARD = 'payments/VERIFY_AFTER_THREEDS_REGISTER_CARD';
export interface VerifyAfterThreeDSRegisterCardAction {
  type: typeof VERIFY_AFTER_THREEDS_REGISTER_CARD;
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: {
      'threeds2.fingerprint'?: string;
      'threeds2.challengeResult'?: string;
    };
  };
  isVerifyForPayment?: boolean;
  addonIds?: [number];
  upgradedMembership?: any;
}

export const SET_OPEN_MODAL = 'payments/SET_OPEN_MODAL';
export interface SetOpenModalAction {
  type: typeof SET_OPEN_MODAL;
  isOpen: boolean;
  callback?: Function;
}

export const CALLBACK_PAYMENT = 'payments/CALLBACK_PAYMENT';
export interface CallbackPaymentAction {
  type: typeof CALLBACK_PAYMENT;
  token: string;
}

export const CALLBACK_PAYMENT_SUCCESS = 'payments/CALLBACK_PAYMENT_SUCCESS';
export interface CallbackPaymentSuccessAction {
  type: typeof CALLBACK_PAYMENT_SUCCESS;
  response: any;
}

export const CALLBACK_PAYMENT_FAIL = 'payments/CALLBACK_PAYMENT_FAIL';
export interface CallbackPaymentFailAction {
  type: typeof CALLBACK_PAYMENT_FAIL;
  error: any;
}

export const GET_ARREARS_INFOMATION = 'payments/GET_ARREARS_INFOMATION';
export interface GetArrearsInformationAction {
  type: typeof GET_ARREARS_INFOMATION;
  memberId: string;
}

export const GET_ARREARS_INFOMATION_SUCCESS = 'payments/GET_ARREARS_INFOMATION_SUCCESS';
export interface GetArrearsInformationSuccessAction {
  type: typeof GET_ARREARS_INFOMATION_SUCCESS;
  response: IArrearsResponse;
}

export const GET_ARREARS_INFOMATION_FAIL = 'payments/GET_ARREARS_INFOMATION_FAIL';
export interface GetArrearsInformationFailAction {
  type: typeof GET_ARREARS_INFOMATION_FAIL;
  error: any;
}

export const MAKE_PAYMENT = 'payments/MAKE_PAYMENT';
export interface MakePaymentAction {
  type: typeof MAKE_PAYMENT;
  amountValue: number;
  payment: {
    paymentMethod: any;
    browserInfo: any;
    paymentType: string;
  };
  enableRecurring: boolean;
  dropinForm: any;
  addonIds?: [number];
  upgradedMembership?: any;
}

export const MAKE_PAYMENT_SUCCESS = 'payments/MAKE_PAYMENT_SUCCESS';
export interface MakePaymentSuccessAction {
  type: typeof MAKE_PAYMENT_SUCCESS;
  paymentResponse: any;
}

export const MAKE_PAYMENT_FAIL = 'payments/MAKE_PAYMENT_FAIL';
export interface MakePaymentFailAction {
  type: typeof MAKE_PAYMENT_FAIL;
  errorMess: any;
}

export const SAVE_CARD_ONLINE_FAIL = 'payments/SAVE_CARD_ONLINE_FAIL';
export interface SaveCardOnlineFailAction {
  type: typeof SAVE_CARD_ONLINE_FAIL;
  error: any;
}

export const DONE_MAKE_PAYMENT = 'payments/DONE_MAKE_PAYMENT';
export interface DoneMakePaymentAction {
  type: typeof DONE_MAKE_PAYMENT;
  purpose: string;
}

export const DONE_MAKE_PAYMENT_WITH_RECEIVE = 'payments/DONE_MAKE_PAYMENT_WITH_RECEIVE';
export interface doneMakePaymentWithReceivedAction {
  type: typeof DONE_MAKE_PAYMENT_WITH_RECEIVE;
  purpose: string;
}

export const LOAD_EZYPAY_DISABLED = 'payments/LOAD_EZYPAY_DISABLED';
export interface loadEzyPayDisabledAction {
  type: typeof LOAD_EZYPAY_DISABLED;
}

export const UPDATE_EZYPAY_DISABLED = 'payments/UPDATE_EZYPAY_DISABLED';
export interface CheckEzyPayDisabled {
  type: typeof UPDATE_EZYPAY_DISABLED;
  isEzyPayDisabled: boolean;
}


export const UPDATE_EZYPAY_INFO = "payments/UPDATE_EZYPAY_INFO";
export interface UpdateEzypayInfo {
  type: typeof UPDATE_EZYPAY_INFO;
  ezyPayInfo: EzyPayInfo | null
}


export type PaymentAction =
  | GetPaymentMethodAction
  | GetPaymentMethodSucessAction
  | GetPaymentMethodFailAction
  | EditPaymentAction
  | EditPaymentSucessAction
  | EditPaymentFailAction
  | PayWithRegisterCardAction
  | PayWithRegisterCardFailAction
  | PayWithRegisterCardSucessAction
  | DoneUpdatePaymentAction
  | DonePayWithRegisterCardAction
  | ClearPaymentAction
  | VerifyAfterThreeDSEditPaymentAction
  | VerifyAfterThreeDSAction
  | SetOpenModalAction
  | CallbackPaymentAction
  | CallbackPaymentSuccessAction
  | CallbackPaymentFailAction
  | GetArrearsInformationAction
  | GetArrearsInformationSuccessAction
  | GetArrearsInformationFailAction
  | VerifyAfterThreeDSRegisterCardAction
  | MakePaymentAction
  | MakePaymentSuccessAction
  | MakePaymentFailAction
  | SaveCardOnlineFailAction
  | DoneMakePaymentAction
  | doneMakePaymentWithReceivedAction
  | CheckEzyPayDisabled
  | UpdateEzypayInfo;
