import PERSONAL_CONSTANTS from "constants/personalConstant";
import moment from "moment";

import { PaymentPrimary } from "types/IPayment";

export const convertPaymentMethod = (value: string) => {
  switch (value) {
    case PERSONAL_CONSTANTS.CREDIT_CARD:
    case PERSONAL_CONSTANTS.CC:
      return PERSONAL_CONSTANTS.CARD;
    case PERSONAL_CONSTANTS.DD:
    case PERSONAL_CONSTANTS.DIRECT_DEBIT:
      return PERSONAL_CONSTANTS.DIRECT_DEBIT;
    default:
      return value;
  }
};

export const isCreditCard = (primaryPayment: PaymentPrimary) => {
  return primaryPayment.paymentMethodTypeCode === "CC";
};

export const isDirectDebit = (primaryPayment: PaymentPrimary) => {
  return isPayTo(primaryPayment) || isBank(primaryPayment);
};

export const isBank = (primaryPayment: PaymentPrimary) => {
  return (
    primaryPayment.paymentMethodTypeCode === "DD" &&
    primaryPayment.cardType === "BANK"
  );
};

export const isPayTo = (primaryPayment: PaymentPrimary) => {
  return (
    primaryPayment.paymentMethodTypeCode === "DD" &&
    primaryPayment.cardType === "PAYTO"
  );
};

export const getPaymentName = (primaryPayment: PaymentPrimary) => {
  if (isPayTo(primaryPayment) || isBank(primaryPayment)) {
    return "Direct Debit";
  }

  return "Card";
};

export const getAccountInfo = (paymentPrimary: PaymentPrimary) => {
  if (paymentPrimary.accountNumber) {
    return paymentPrimary.accountNumber;
  }

  if (paymentPrimary.payIdViaEmail) {
    return paymentPrimary.payIdViaEmail;
  }

  if (paymentPrimary.payIdViaPhoneNumber) {
    return paymentPrimary.payIdViaPhoneNumber;
  }

  return "";
};

export const getExpireDate = (expiryDate: string) => {
  const monthYear = moment(expiryDate, "MMYY");
  const formattedDate = monthYear.format("MMMM YYYY");
  return formattedDate;
};
