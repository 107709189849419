import { IArrearsResponse } from 'types/IPayment';
import { paymentActionTypes as actionTypes } from '../actionTypes'; 
import { EzyPayInfo } from 'types/EzyPay/EzyPayInfo';

function getPaymentMethod(memberId: number): actionTypes.GetPaymentMethodAction {
  return {
    type: actionTypes.GET_PAYMENT_METHOD,
    memberId,
  };
}

function getPaymentMethodSuccess(paymentMethodsResponse: any): actionTypes.GetPaymentMethodSucessAction {
  return {
    type: actionTypes.GET_PAYMENT_METHOD_SUCCESS,
    paymentMethodsResponse,
  };
}

function getPaymentMethodFail({ error }: any): actionTypes.GetPaymentMethodFailAction {
  return {
    type: actionTypes.GET_PAYMENT_METHOD_FAIL,
    error,
  };
}

function editPayment(membershipDetails: any, dataState: any, dropinForm: any): actionTypes.EditPaymentAction {
  return {
    type: actionTypes.EDIT_PAYMENT,
    membershipDetails,
    dataState,
    dropinForm,
  };
}

function editPaymentSuccess(paymentResponse: any): actionTypes.EditPaymentSucessAction {
  return {
    type: actionTypes.EDIT_PAYMENT_SUCCESS,
    paymentResponse,
  };
}

function editPaymentFail(error: any): actionTypes.EditPaymentFailAction {
  return {
    type: actionTypes.EDIT_PAYMENT_FAIL,
    error,
  };
}

function doneUpdatePayment(): actionTypes.DoneUpdatePaymentAction {
  return {
    type: actionTypes.DONE_UPDATE_PAYMENT,
  };
}

function verifyAfterThreeDSEditPayment({
  paymentData,
  verifyData,
}: {
  paymentData: string;
  verifyData: any;
}): actionTypes.VerifyAfterThreeDSEditPaymentAction {
  return {
    type: actionTypes.VERIFY_AFTER_THREEDS_EDIT_PAYMENT,
    paymentData,
    verifyData,
  };
}

function verifyAfterThreeDS({
  paymentData,
  verifyData,
  isVerifyForPayment,
  addonIds,
  upgradedMembership,
}: {
  paymentData: string;
  verifyData: any;
  isVerifyForPayment?: boolean;
  addonIds?: [number];
  upgradedMembership?: any;
}): actionTypes.VerifyAfterThreeDSAction {
  return {
    type: actionTypes.VERIFY_AFTER_THREEDS,
    paymentData,
    verifyData,
    isVerifyForPayment,
    addonIds,
    upgradedMembership,
  };
}

function verifyAfterThreeDSRegisterCard({
  paymentData,
  verifyData,
  isVerifyForPayment,
  addonIds,
  upgradedMembership,
}: {
  paymentData: string;
  verifyData: any;
  isVerifyForPayment?: boolean;
  addonIds?: [number];
  upgradedMembership?: any;
}): actionTypes.VerifyAfterThreeDSRegisterCardAction {
  return {
    type: actionTypes.VERIFY_AFTER_THREEDS_REGISTER_CARD,
    paymentData,
    verifyData,
    isVerifyForPayment,
    addonIds,
    upgradedMembership,
  };
}

function clearPaymentState(clearResponse?: boolean): actionTypes.ClearPaymentAction {
  return {
    type: actionTypes.CLEAR_PAYMENT_SUCCESS,
    clearResponse,
  };
}

function setOpenModal(isOpen: boolean, callback?: Function): actionTypes.SetOpenModalAction {
  return {
    type: actionTypes.SET_OPEN_MODAL,
    isOpen,
    callback,
  };
}

function callbackPayment(token: string): actionTypes.CallbackPaymentAction {
  return {
    type: actionTypes.CALLBACK_PAYMENT,
    token,
  };
}

function callbackPaymentSuccess(response: any): actionTypes.CallbackPaymentSuccessAction {
  return {
    type: actionTypes.CALLBACK_PAYMENT_SUCCESS,
    response,
  };
}

function callbackPaymentFail({ error }: any): actionTypes.CallbackPaymentFailAction {
  return {
    type: actionTypes.CALLBACK_PAYMENT_FAIL,
    error,
  };
}

function getArrearInformation(memberId: string): actionTypes.GetArrearsInformationAction {
  return {
    type: actionTypes.GET_ARREARS_INFOMATION,
    memberId,
  };
}

function getArrearInformationSuccess(response: IArrearsResponse): actionTypes.GetArrearsInformationSuccessAction {
  return {
    type: actionTypes.GET_ARREARS_INFOMATION_SUCCESS,
    response,
  };
}

function getArrearInformationFail({ error }: any): actionTypes.GetArrearsInformationFailAction {
  return {
    type: actionTypes.GET_ARREARS_INFOMATION_FAIL,
    error,
  };
}

function payWithRegisterCard(
  amount: number,
  addonIds?: [number],
  upgradedMembership?: any
): actionTypes.PayWithRegisterCardAction {
  return {
    type: actionTypes.PAY_WITH_REGISTER_CARD,
    amount,
    addonIds,
    upgradedMembership,
  };
}

function payWithRegisterCardSuccess(paymentResponse: any): actionTypes.PayWithRegisterCardSucessAction {
  return {
    type: actionTypes.PAY_WITH_REGISTER_CARD_SUCCESS,
    paymentResponse,
  };
}

function payWithRegisterCardFail(error: any): actionTypes.PayWithRegisterCardFailAction {
  return {
    type: actionTypes.PAY_WITH_REGISTER_CARD_FAIL,
    error,
  };
}

function makePayment(
  amountValue: number,
  payment: any,
  enableRecurring: boolean,
  dropinForm: any,
  addonIds?: [number],
  upgradedMembership?: any
): actionTypes.MakePaymentAction {
  return {
    type: actionTypes.MAKE_PAYMENT,
    amountValue,
    payment,
    enableRecurring,
    dropinForm,
    addonIds,
    upgradedMembership,
  };
}

function makePaymentFail(errorMess: any): actionTypes.MakePaymentFailAction {
  return {
    type: actionTypes.MAKE_PAYMENT_FAIL,
    errorMess,
  };
}

function makePaymentSuccess(paymentResponse: any): actionTypes.MakePaymentSuccessAction {
  return {
    type: actionTypes.MAKE_PAYMENT_SUCCESS,
    paymentResponse,
  };
}

function saveCardOnlineFail({ error }: any): actionTypes.SaveCardOnlineFailAction {
  return {
    type: actionTypes.SAVE_CARD_ONLINE_FAIL,
    error,
  };
}

function doneMakePayment(purpose: string): actionTypes.DoneMakePaymentAction {
  return {
    type: actionTypes.DONE_MAKE_PAYMENT,
    purpose,
  };
}

function doneMakePaymentWithReceived(purpose: string): actionTypes.doneMakePaymentWithReceivedAction {
  return {
    type: actionTypes.DONE_MAKE_PAYMENT_WITH_RECEIVE,
    purpose,
  };
}

function donePayWithRegisterCard(purpose: string): actionTypes.DonePayWithRegisterCardAction {
  return {
    type: actionTypes.DONE_PAY_WITH_REGISTER_CARD,
    purpose,
  };
}

function loadDisabledUseEzyPay(): actionTypes.loadEzyPayDisabledAction {
  return {
    type: actionTypes.LOAD_EZYPAY_DISABLED,
  };
}

function updateIsEzyPayDisabled(isEzyPayDisabled: boolean): actionTypes.CheckEzyPayDisabled {
  return {
    type: actionTypes.UPDATE_EZYPAY_DISABLED,
    isEzyPayDisabled,
  };
} 

function updateEzyPayInfo(ezyPayInfo: EzyPayInfo | null): actionTypes.UpdateEzypayInfo {
  return {
    type: actionTypes.UPDATE_EZYPAY_INFO, 
    ezyPayInfo
  }
}
export {
  getPaymentMethod,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  editPayment,
  editPaymentSuccess,
  editPaymentFail,
  doneUpdatePayment,
  clearPaymentState,
  verifyAfterThreeDSEditPayment,
  verifyAfterThreeDS,
  setOpenModal,
  callbackPayment,
  callbackPaymentSuccess,
  callbackPaymentFail,
  getArrearInformation,
  getArrearInformationSuccess,
  getArrearInformationFail,
  payWithRegisterCard,
  payWithRegisterCardSuccess,
  payWithRegisterCardFail,
  makePayment,
  makePaymentFail,
  makePaymentSuccess,
  saveCardOnlineFail,
  doneMakePayment,
  doneMakePaymentWithReceived,
  donePayWithRegisterCard,
  verifyAfterThreeDSRegisterCard,
  updateIsEzyPayDisabled,
  loadDisabledUseEzyPay,
  updateEzyPayInfo,
};
