import { PaymentDetails } from 'types/IPayment';
import { membershipActionTypes as actionTypes } from '../actionTypes';

function getMembership(): actionTypes.GetMembershipAction {
  return {
    type: actionTypes.GET_MEMBERSHIP,
  };
}

function getMembershipSuccess(membershipDetails: any): actionTypes.GetMembershipSucessAction {
  return {
    type: actionTypes.GET_MEMBERSHIP_SUCCESS,
    membershipDetails,
  };
}

function getMembershipFail({ error }: any): actionTypes.GetMembershipFailAction {
  return {
    type: actionTypes.GET_MEMBERSHIP_FAIL,
    error,
  };
}

function getPackagePlansSuccess(data: {
  brands: any;
  clusterName: string;
  onlinePaymentMethod: string;
  addons: [];
  totalRecurringFee: number;
  paymentMethodTypes: PaymentDetails[];
}): actionTypes.GetPackagePlansSuccessAction {
  return {
    type: actionTypes.GET_PACKAGE_PLANS_SUCCESS,
    data,
  };
}

function getBillingdateAddonsSuccess(billingDateAddons: string): actionTypes.GetBillingDateAddonsSuccessAction {
  return {
    type: actionTypes.GET_BILLING_DATE_ADDONS_SUCCESS,
    billingDateAddons,
  };
} 

export {
  getMembership,
  getMembershipSuccess,
  getMembershipFail,
  getPackagePlansSuccess,
  getBillingdateAddonsSuccess, 
};
