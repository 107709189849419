import { membershipActionTypes as actions } from '../actionTypes';

const initialState = {
  isLoading: true,
  membershipDetails: {},
  error: null,
  brands: [],
  onlinePaymentMethod: null,
  totalRecurringFee: 0,
  paymentMethodTypes: []
};

export default function membershipReducer(state = initialState, action: actions.MembershipAction) {
  switch (action.type) {
    case actions.GET_MEMBERSHIP:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        membershipDetails: { ...state.membershipDetails, ...action.membershipDetails },
        isLoading: false,
      };
    case actions.GET_MEMBERSHIP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        isLoading: false,
      };
    case actions.GET_PACKAGE_PLANS_SUCCESS:
      return {
        ...state,
        brands: action.data.brands,
        membershipDetails: {
          ...state.membershipDetails,
          clusterName: action.data.clusterName,
          addons: action.data.addons,
        },
        onlinePaymentMethod: action.data.onlinePaymentMethod,
        totalRecurringFee: action.data.totalRecurringFee,
        paymentMethodTypes: action.data.paymentMethodTypes,
        isLoading: false,
      };
    case actions.GET_BILLING_DATE_ADDONS_SUCCESS:
      return {
        ...state,
        membershipDetails: { ...state.membershipDetails, billingDateAddons: action.billingDateAddons },
        isLoading: false,
      };
    default:
      return state;
  }
}
