import React, { useEffect, useState } from 'react';

import { Card } from '@material-ui/core';
import ConfirmModal from 'components/ModalConfirm';
import CustomInput from 'components/InputField';
import { FEATURE_CODE } from 'constants/baseConstants';
import { IPersonalBody } from 'types/IPersonal';
import constants from 'constants/personalConstant';
import { getIconUrl } from 'utility/helpers';
import moment from 'moment';
import { personalActions } from 'redux/actionCreators';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { resetPersonalState } from 'redux/actionCreators/personal';
import { useDeviceCheck } from 'utility/screenDetect';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';

interface PersonalInfoProps {
  setDirtyTabForm: Function;
}

export const PersonalInfo = (props: PersonalInfoProps) => {
  const { setDirtyTabForm } = props;
  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const { isEditSuccess, regexs, fields: inputFields } = useTypedSelector((state) => state.personal);

  const firstNameField = inputFields.find((ip) => ip.key === constants.FIRST_NAME_KEY);
  const lastNameField = inputFields.find((ip) => ip.key === constants.LAST_NAME_KEY);
  const genderField = inputFields.find((ip) => ip.key === constants.GENDER_KEY);
  const emailField = inputFields.find((ip) => ip.key === constants.EMAIL_KEY);
  const birthDateField = inputFields.find((ip) => ip.key === constants.BIRTH_DATE_KEY);
  const mobilePhoneField = inputFields.find((ip) => ip.key === constants.MOBILE_PHONE_KEY);
  const homePhoneField = inputFields.find((ip) => ip.key === constants.HOME_PHONE_KEY);
  const contactNameField = inputFields.find((ip) => ip.key === constants.CONTACT_NAME_KEY);
  const contactNumberField = inputFields.find((ip) => ip.key === constants.CONTACT_NUMBER_KEY);
  const corporateNameField = inputFields.find((ip) => ip.key === constants.CORPORATE_NAME_KEY);
  const numberNameField = inputFields.find((ip) => ip.key === constants.NUMBER_NAME_KEY);
  const addressLine1Field = inputFields.find((ip) => ip.key === constants.ADDRESS_LINE_1_KEY);
  const addressLine2Field = inputFields.find((ip) => ip.key === constants.ADDRESS_LINE_2_KEY);
  const postalCodeField = inputFields.find((ip) => ip.key === constants.POST_CODE_KEY);
  const cityField = inputFields.find((ip) => ip.key === constants.CITY_KEY);
  const countryField = inputFields.find((ip) => ip.key === constants.COUNTRY_KEY);
  const preferredLanguageField = inputFields.find((ip) => ip.key === constants.PREFERRED_LANGUAGE_KEY);

  const dateRegexObjFromJol = regexs.find((ph) => ph.key === constants.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const dateRegexFromJol = dateRegexObjFromJol.value.toUpperCase();
  const dateRegexFromFieldsConfig = birthDateField && birthDateField.formatDateTime?.toUpperCase();

  const [firstName, setFirstName] = useState(membershipDetails.firstName || '');
  const [lastName, setLastName] = useState(membershipDetails.lastName || '');
  const [dateOfBirth, setDateOfBirth] = useState(
    moment(membershipDetails.dateOfBirth, constants.DATE_REGEX_BE_DEFAULT).format(dateRegexFromJol)
  );
  const [emailAddress, setEmailAddress] = useState(membershipDetails.emailAddress || '');
  const [gender, setGender] = useState(membershipDetails.gender || '');
  const [emergencyContactName, setEmergencyContactName] = useState(membershipDetails.emergencyContactName || '');
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(membershipDetails.emergencyContactPhone || '');
  const [corporateName, setCorporateName] = useState(membershipDetails.corporateName || '');
  const [mobilePhone, setMobilePhone] = useState(membershipDetails.mobileNumber || '');
  const [homePhone, setHomePhone] = useState(membershipDetails.homephoneNumber || '');
  const [openConfirmSave, setOpenConfirmSave] = useState(false);
  const [homePhoneError, setHomePhoneError] = useState('');
  const [emergencyNumberError, setEmergencyNumberError] = useState('');
  const [mobilePhoneError, setMobilePhoneError] = useState('');
  const [birthdayError, setBirthdayError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emergencyNameError, setEmergencyNameError] = useState('');

  const [numberName, setNumberName] = useState(membershipDetails.numberName || '');
  const [numberNameError, setNumberNameError] = useState('');
  const [addressLine1, setAddressLine1] = useState(membershipDetails.addressLine1 || '');
  const [addressLine1Error, setAddressLine1Error] = useState('');
  const [addressLine2, setAddressLine2] = useState(membershipDetails.addressLine2 || '');
  const [addressLine2Error, setAddressLine2Error] = useState('');
  const [city, setCity] = useState(membershipDetails.city || '');
  const [cityError, setCityError] = useState('');
  const [postalCode, setPostalCode] = useState(membershipDetails.postalCode || '');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [country, setCountry] = useState(membershipDetails.country || '');
  const [countryError, setCountryError] = useState('');
  const [preferredLanguage, setPreferredLanguage] = useState(membershipDetails.preferredLanguageTypeCode || '');

  const [dateOfBirthRegex, setDateOfBirthRegex] = useState(dateRegexFromFieldsConfig || dateRegexFromJol);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [, , isMobile] = useDeviceCheck();

  const { editPersonalInfo } = personalActions;

  const dispatch = useDispatch();
  const editPersonalInfoAction = (memberId: number, body: IPersonalBody) => dispatch(editPersonalInfo(memberId, body));

  useEffect(() => {
    document.getElementById(`input-${t('SS_PERSONAL_FIRST_NAME')}`)?.focus();
    validateFields();
  }, []);

  useEffect(() => {
    document.title = t('SS_PERSONAL');
  }, [t('SS_LOCAL')]);

  const firstNameConfig = useMemo(
    () => ({
      field: firstNameField,
      regexKey: PERSONAL_CONSTANTS.JOL_FIRST_NAME_REGEX,
      invalidMessage: 'SS_PERSONAL_INVALID_FIRST_NAME',
      requiredMessage: 'SS_PERSONAL_REQUIRED_FIRST_NAME',
      setError: setFirstNameError,
    }),
    [firstNameField]
  );

  const lastNameConfig = useMemo(
    () => ({
      field: lastNameField,
      regexKey: PERSONAL_CONSTANTS.JOL_LAST_NAME_REGEX,
      invalidMessage: 'SS_PERSONAL_INVALID_LAST_NAME',
      requiredMessage: 'SS_PERSONAL_REQUIRED_LAST_NAME',
      setError: setLastNameError,
    }),
    [lastNameField]
  );

  const emailConfig = useMemo(
    () => ({
      field: emailField,
      regexKey: PERSONAL_CONSTANTS.JOL_EMAIL_REGEX,
      invalidMessage: 'SS_PERSONAL_INVALID_EMAIL',
      requiredMessage: 'SS_PERSONAL_REQUIRED_EMAIL',
      setError: setEmailError,
    }),
    [emailField]
  );

  const numberNameConfig = useMemo(
    () => ({
      field: numberNameField,
      regexKey: PERSONAL_CONSTANTS.NUMBER_NAME_REGEX_KEY,
      invalidMessage: 'SS_PERSONAL_INVALID_NUMBER_NAME',
      requiredMessage: 'SS_PERSONAL_REQUIRED_NUMBER_NAME',
      setError: setNumberNameError,
    }),
    [numberNameField, setNumberNameError]
  );

  const addressLine1Config = useMemo(
    () => ({
      field: addressLine1Field,
      regexKey: PERSONAL_CONSTANTS.ADDRESS_LINE_1_REGEX_KEY,
      invalidMessage: 'SS_PERSONAL_INVALID_ADDRESS_LINE_1',
      requiredMessage: 'SS_PERSONAL_REQUIRED_ADDRESS_LINE_1',
      setError: setAddressLine1Error,
    }),
    [addressLine1Field, setAddressLine1Error]
  );

  const addressLine2Config = useMemo(
    () => ({
      field: addressLine2Field,
      regexKey: PERSONAL_CONSTANTS.ADDRESS_LINE_2_REGEX_KEY,
      invalidMessage: 'SS_PERSONAL_INVALID_ADDRESS_LINE_2',
      requiredMessage: 'SS_PERSONAL_REQUIRED_ADDRESS_LINE_2',
      setError: setAddressLine2Error,
    }),
    [addressLine2Field, setAddressLine2Error]
  );

  const postalCodeConfig = useMemo(
    () => ({
      field: postalCodeField,
      regexKey: PERSONAL_CONSTANTS.POST_CODE_REGEX_KEY,
      invalidMessage: 'SS_PERSONAL_INVALID_POST_CODE',
      requiredMessage: 'SS_PERSONAL_REQUIRED_POST_CODE',
      setError: setPostalCodeError,
    }),
    [postalCodeField, setPostalCodeError]
  );

  const cityConfig = useMemo(
    () => ({
      field: cityField,
      regexKey: PERSONAL_CONSTANTS.CITY_REGEX_KEY,
      invalidMessage: 'SS_PERSONAL_INVALID_CITY',
      requiredMessage: 'SS_PERSONAL_REQUIRED_CITY',
      setError: setCityError,
    }),
    [cityField, setCityError]
  );
  const countryConfig = useMemo(
    () => ({
      field: countryField,
      regexKey: '',
      invalidMessage: 'SS_PERSONAL_INVALID_COUNTRY',
      requiredMessage: 'SS_PERSONAL_REQUIRED_COUNTRY',
      setError: setCountryError,
    }),
    [countryField, setCountryError]
  );

  const preferredLanguageOptions = useMemo(
    () =>
      membershipDetails.memberPreferredLanguages.map((item: any) => ({
        name: item.languageName,
        value: item.reasonCode,
      })),
    [membershipDetails]
  );

  useEffect(() => {
    if (isEditSuccess) {
      setDirtyTabForm(false);
      dispatch(resetPersonalState());
      emitNotify(
        enqueueSnackbar,
        t('SS_NOTIFICATION_PERSONAL_DETAILS'),
        t('SS_NOTIFICATION_PERSONAL_INFORMATION_SAVING'),
        'success'
      );
    }
  }, [isEditSuccess]);

  const onValidateBirthday = (value: string, formatString?: string) => {
    if (!birthDateField || !birthDateField.editable) {
      return true;
    }

    if (moment(value, formatString).format(formatString) !== value) {
      if (value.length === 0) {
        setBirthdayError(t('SS_PERSONAL_REQUIRED_DOB'));
        return false;
      } else {
        setBirthdayError(t('SS_PERSONAL_INVALID_DATE'));
        return false;
      }
    } else {
      setBirthdayError('');
    }
    return true;
  };

  const onChangeBirthday = (value: string, onChange: Function, formatString?: string) => {
    setDateOfBirthRegex('');
    onChange(value);
    onValidateBirthday(value, formatString);
  };

  const onValidateEmergencyContactName = (contactName: string): boolean => {
    if (!contactNameField || !contactNameField.editable) {
      return true;
    }
    const contactNameRegex = regexs.find((ph) => ph.key === constants.EMERGENCY_NAME_REGEX) || {
      key: '',
      value: '',
    };
    const regex = new RegExp(contactNameRegex.value);
    if (contactName && !regex.test(contactName)) {
      setEmergencyNameError(t('SS_PERSONAL_INVALID_EMERGENCY_NAME'));
      return false;
    } else if (contactNameField.isRequired && !contactName) {
      setEmergencyNameError(t('SS_PERSONAL_REQUIRED_EMERGENCY_NAME'));
      return false;
    } else {
      setEmergencyNameError('');
      return true;
    }
  };

  const onChangeEmergencyContactName = (contactName: string) => {
    onValidateEmergencyContactName(contactName);
    setEmergencyContactName(contactName);
  };

  const genderOptions = useMemo(
    () => [
      { name: t('SS_PERSONAL_GENDER_MALE'), value: 'Male' },
      { name: t('SS_PERSONAL_GENDER_FEMALE'), value: 'Female' },
    ],
    [t]
  );

  const onFilterPhoneRegex = (value: string, onChange: Function, isRequired?: boolean, phoneName?: string) => {
    if (phoneName === constants.PHONE && (!homePhoneField || !homePhoneField.editable)) {
      return true;
    }
    if (phoneName === constants.MOBILE_PHONE && (!mobilePhoneField || !mobilePhoneField.editable)) {
      return true;
    }
    if (phoneName === constants.EMERGENCY_NUMBER && (!contactNumberField || !contactNumberField.editable)) {
      return true;
    }
    if (phoneName && value) {
      const keyString =
        phoneName === constants.EMERGENCY_NUMBER
          ? constants.PREFIX_VALIDATION_REGEX.replace('{{PhoneType}}', constants.MOBILE_PHONE)
          : constants.PREFIX_VALIDATION_REGEX.replace('{{PhoneType}}', phoneName);
      const keyStringDefault = constants.VALIDATION_REGEX_DEFAULT;
      const filterPhoneObj = regexs.find((ph) => ph.key === keyString || ph.key === keyStringDefault) || {
        key: '',
        value: '',
      };
      onChange(value);

      const regex = new RegExp(filterPhoneObj.value);
      if (!regex.test(value)) {
        if (phoneName === constants.PHONE) {
          setHomePhoneError(t('SS_PERSONAL_INVALID_HOME_PHONE'));
          return false;
        }
        if (phoneName === constants.MOBILE_PHONE) {
          setMobilePhoneError(t('SS_PERSONAL_INVALID_MOBILE_PHONE'));
          return false;
        }
        if (phoneName === constants.EMERGENCY_NUMBER) {
          setEmergencyNumberError(t('SS_PERSONAL_INVALID_EMERGENCY_NUMBER'));
          return false;
        }
      } else {
        phoneName === constants.PHONE && setHomePhoneError('');
        phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
        phoneName === constants.EMERGENCY_NUMBER && setEmergencyNumberError('');
      }
    } else if (phoneName) {
      onChange(value);
      if (isRequired) {
        if (phoneName === constants.PHONE) {
          setHomePhoneError(t('SS_PERSONAL_REQUIRED_HOME_PHONE'));
          return false;
        }
        if (phoneName === constants.MOBILE_PHONE) {
          setMobilePhoneError(t('SS_PERSONAL_REQUIRED_MOBILE_PHONE'));
          return false;
        }
        if (phoneName === constants.EMERGENCY_NUMBER) {
          setEmergencyNumberError(t('SS_PERSONAL_REQUIRED_EMERGENCY_NUMBER'));
          return false;
        }
      } else {
        phoneName === constants.PHONE && setHomePhoneError('');
        phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
        phoneName === constants.EMERGENCY_NUMBER && setEmergencyNumberError('');
      }
    } else {
      phoneName === constants.PHONE && setHomePhoneError('');
      phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
      phoneName === constants.EMERGENCY_NUMBER && setEmergencyNumberError('');
      const filterValue = value.replace(/[^-.0-9\s]/g, '').replace(/\s/g, '');
      onChange(filterValue);
    }
    return true;
  };

  useEffect(() => {
    setDirtyTabForm(checkChangedForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    gender,
    emailAddress,
    dateOfBirth,
    emergencyContactName,
    emergencyContactPhone,
    corporateName,
    mobilePhone,
    homePhone,
    numberName,
    addressLine1,
    addressLine2,
    postalCode,
    city,
    country,
    preferredLanguage,
  ]);

  const isChangedField = (newValue = '', oldValue = '') => {
    if (!newValue && !oldValue) {
      return false;
    }
    return newValue !== oldValue;
  };

  const checkChangedForm = () => {
    if (membershipDetails) {
      const dirtyFirstName = isChangedField(firstName, membershipDetails.firstName);
      const dirtyLastName = isChangedField(lastName, membershipDetails.lastName);
      const dirtyEmailAddress = isChangedField(emailAddress, membershipDetails.emailAddress);
      const dirtyGender = isChangedField(gender, membershipDetails.gender);
      const dirtyDOB =
        dateOfBirth.length > 0 &&
        isChangedField(
          moment(dateOfBirth, dateRegexFromJol).format(constants.DATE_REGEX_BE_DEFAULT),
          membershipDetails.dateOfBirth
        );
      const dirtyCompanyName = isChangedField(corporateName, membershipDetails.corporateName);
      const dirtyEmergencyContactName = isChangedField(emergencyContactName, membershipDetails.emergencyContactName);
      const dirtyEmergencyContactPhone = isChangedField(emergencyContactPhone, membershipDetails.emergencyContactPhone);
      const dirtyMobilePhone = isChangedField(mobilePhone, membershipDetails.mobileNumber);
      const dirtyHomePhone = isChangedField(homePhone, membershipDetails.homephoneNumber);
      const dirtyNumberName = isChangedField(numberName, membershipDetails.numberName);
      const dirtyAddressLine1 = isChangedField(addressLine1, membershipDetails.addressLine1);
      const dirtyAddressLine2 = isChangedField(addressLine2, membershipDetails.addressLine2);
      const dirtypostalCode = isChangedField(postalCode, membershipDetails.postalCode);
      const dirtyCity = isChangedField(city, membershipDetails.city);
      const dirtyCountry = isChangedField(country, membershipDetails.country);
      const dirtyPreferredLanguage = isChangedField(preferredLanguage, membershipDetails.preferredLanguageTypeCode);

      return (
        dirtyFirstName ||
        dirtyLastName ||
        dirtyEmailAddress ||
        dirtyGender ||
        dirtyDOB ||
        dirtyCompanyName ||
        dirtyEmergencyContactName ||
        dirtyEmergencyContactPhone ||
        dirtyMobilePhone ||
        dirtyHomePhone ||
        dirtyNumberName ||
        dirtyAddressLine1 ||
        dirtyAddressLine2 ||
        dirtypostalCode ||
        dirtyCity ||
        dirtyCountry ||
        dirtyPreferredLanguage
      );
    }
    return false;
  };

  const checkFieldErrors = () =>
    !!homePhoneError ||
    !!mobilePhoneError ||
    !!emergencyNumberError ||
    !!birthdayError ||
    !!emailError ||
    !!firstNameError ||
    !!lastNameError ||
    !!emergencyNameError ||
    !!numberNameError ||
    !!addressLine1Error ||
    !!addressLine2Error ||
    !!postalCodeError ||
    !!cityError ||
    !!countryError;

  const isSendableField = (
    config:
      | {
        isVisible: boolean;
        editable: boolean;
      }
      | undefined,
    value: string | undefined
  ) => {
    if (!config) {
      return undefined;
    }
    if (config.isVisible && config.editable) {
      return value?.trim();
    }
    return undefined;
  };

  const handleSaveInfo = () => {
    // If the fields is non-editable then not sending them to server
    const body = {
      firstName: isSendableField(firstNameField, firstName),
      lastName: isSendableField(lastNameField, lastName),
      emailAddress: isSendableField(emailField, emailAddress),
      birthDate: isSendableField(
        birthDateField,
        moment(dateOfBirth, dateRegexFromJol).format(constants.DATE_REGEX_BE_DEFAULT)
      ),
      gender: isSendableField(genderField, gender),
      contactName: isSendableField(contactNameField, emergencyContactName),
      contactNumber: isSendableField(contactNumberField, emergencyContactPhone),
      corporateName: isSendableField(corporateNameField, corporateName),
      mobilePhone: isSendableField(mobilePhoneField, mobilePhone),
      homePhone: isSendableField(homePhoneField, homePhone),
      numberName: isSendableField(numberNameField, numberName),
      addressLine1: isSendableField(addressLine1Field, addressLine1),
      addressLine2: isSendableField(addressLine2Field, addressLine2),
      postalCode: isSendableField(postalCodeField, postalCode),
      city: isSendableField(cityField, city),
      country: isSendableField(countryField, country),
      preferredLanguage: isSendableField(preferredLanguageField, preferredLanguage),
      memberId: membershipDetails.memberId,
      featureCode: FEATURE_CODE,
    };
    editPersonalInfoAction(membershipDetails.memberId, body);
  };

  const handleSave = () => {
    handleSaveInfo();
    setOpenConfirmSave(false);
  };

  const validateFields = (): boolean => {
    const validateFirstName = onValidateField(firstName, firstNameConfig);
    const validateLastName = onValidateField(lastName, lastNameConfig);
    const validateEmail = onValidateField(emailAddress, emailConfig);
    const isValidEmergencyName = onValidateEmergencyContactName(emergencyContactName);
    const isValidBirthday = onValidateBirthday(dateOfBirth, dateRegexFromJol);
    const isValidMobilePhone = onFilterPhoneRegex(
      mobilePhone,
      setMobilePhone,
      mobilePhoneField && mobilePhoneField.isRequired,
      constants.MOBILE_PHONE
    );
    const isValidHomePhone = onFilterPhoneRegex(
      homePhone,
      setHomePhone,
      homePhoneField && homePhoneField.isRequired,
      constants.PHONE
    );
    const isValidEmergencyNumber = onFilterPhoneRegex(
      emergencyContactPhone,
      setEmergencyContactPhone,
      contactNumberField && contactNumberField.isRequired,
      constants.EMERGENCY_NUMBER
    );

    const validateNumberName = onValidateField(numberName, numberNameConfig);
    const validateAddressLine1 = onValidateField(addressLine1, addressLine1Config);
    const validateAddressLine2 = onValidateField(addressLine2, addressLine2Config);
    const validatePostalCode = onValidateField(postalCode, postalCodeConfig);
    const validateCity = onValidateField(city, cityConfig);
    const validateCountry = onValidateField(country, countryConfig);

    return (
      validateEmail.isValid &&
      isValidBirthday &&
      isValidMobilePhone &&
      isValidHomePhone &&
      validateFirstName.isValid &&
      validateLastName.isValid &&
      isValidEmergencyNumber &&
      isValidEmergencyName &&
      validateNumberName.isValid &&
      validateAddressLine1.isValid &&
      validateAddressLine2.isValid &&
      validatePostalCode.isValid &&
      validateCity.isValid &&
      validateCountry.isValid
    );
  };

  const onSaveButtonClick = () => {
    gtag('event', 'Button', {
      event_action: 'PDSave',
      event_label: 'PDSaveClicked',
    });
    validateFields() && setOpenConfirmSave(true);
  };

  if (Object.keys(membershipDetails).length === 0) return null;

  const checkDisableSaveBtn = () => {
    return !checkChangedForm() || checkFieldErrors();
  };

  const onValidateField = (newValue: any, configs: any) => {
    const { field, regexKey, invalidMessage, requiredMessage } = configs;

    if (!field || !field.editable) {
      return {
        isValid: true,
        error: '',
      };
    }
    const regexObj = regexs.find((reg) => reg.key === regexKey) || {
      key: '',
      value: '',
    };
    const regex = new RegExp(regexObj.value);
    if (newValue && !regex.test(newValue)) {
      configs.setError(t(invalidMessage));
      return {
        isValid: false,
        error: t(invalidMessage),
      };
    } else if (field.isRequired && !newValue) {
      configs.setError(t(requiredMessage));
      return {
        isValid: false,
        error: t(requiredMessage),
      };
    } else {
      return {
        isValid: true,
        error: '',
      };
    }
  };

  const onChangeInputValue = (newValue: any, updateValue: (newValue: string) => void, configs: any) => {
    const { error } = onValidateField(newValue, configs);
    configs.setError(error);
    updateValue(newValue);
  };

  return (
    <div className="personal">
      <Card className="card-info-personal">
        <p className="card-info-personal__title">{t('SS_PERSONAL_INFO')}</p>
        <div className="personal-info">
          <div className="row m-0">
            {firstNameField && firstNameField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_FIRST_NAME')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInputValue(e.target.value, setFirstName, firstNameConfig)
                  }
                  value={firstName}
                  endAdornment={
                    !firstNameField.editable && <img src={getIconUrl('Disable')} alt="disabled-first-name" />
                  }
                  placeholder={t('SS_PERSONAL_FIRST_NAME_PLACEHOLDER')}
                  maxLength={firstNameField.maxLength || 40}
                  isRequired={firstNameField.isRequired}
                  isDisabled={!firstNameField.editable}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_FIRST_NAME')}
                  errorMessage={firstNameError}
                />
              </div>
            )}
            {lastNameField && lastNameField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_LAST_NAME')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInputValue(e.target.value, setLastName, lastNameConfig)
                  }
                  value={lastName}
                  endAdornment={!lastNameField.editable && <img src={getIconUrl('Disable')} alt="disabled-last-name" />}
                  placeholder={t('SS_PERSONAL_LAST_NAME_PLACEHOLDER')}
                  maxLength={lastNameField.maxLength || 40}
                  isRequired={lastNameField.isRequired}
                  isDisabled={!lastNameField.editable}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_LAST_NAME')}
                  errorMessage={lastNameError}
                />
              </div>
            )}
          </div>
          <div className="row m-0">
            {genderField && genderField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_GENDER')}
                  onChangeSelect={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                    setGender(e.target.value)
                  }
                  value={gender}
                  selectOptions={genderOptions}
                  type="select"
                  endAdornment={!genderField.editable && <img src={getIconUrl('Disable')} alt="disabled-gender" />}
                  isRequired={genderField.isRequired}
                  isDisabled={!genderField.editable}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_GENDER')}
                />
              </div>
            )}
            {emailField && emailField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_EMAIL')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInputValue(e.target.value, setEmailAddress, emailConfig)
                  }
                  value={emailAddress}
                  endAdornment={!emailField.editable && <img src={getIconUrl('Disable')} alt="disabled-email" />}
                  maxLength={emailField.maxLength || 255}
                  isRequired={emailField.isRequired}
                  isDisabled={!emailField.editable}
                  errorMessage={emailError}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_EMAIL')}
                />
              </div>
            )}
          </div>
          <div className="row m-0">
            {birthDateField && birthDateField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_DOB')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeBirthday(e.target.value, setDateOfBirth, dateRegexFromJol)
                  }
                  value={dateOfBirth}
                  endAdornment={!birthDateField.editable && <img src={getIconUrl('Disable')} alt="disabled-dob" />}
                  isRequired={birthDateField.isRequired}
                  isDisabled={!birthDateField.editable}
                  errorMessage={birthdayError}
                  formatForDate={dateOfBirthRegex}
                  defaultFormatForDate={dateRegexFromJol}
                  onFocus={() => setDateOfBirthRegex(dateRegexFromJol)}
                  onBlur={() => setDateOfBirthRegex(dateRegexFromFieldsConfig || dateRegexFromJol)}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_DOB')}
                />
              </div>
            )}
            {corporateNameField && corporateNameField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_CORPORATE_NAME')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCorporateName(e.target.value)}
                  value={corporateName}
                  endAdornment={
                    !corporateNameField.editable && <img src={getIconUrl('Disable')} alt="disabled-company" />
                  }
                  maxLength={corporateNameField.maxLength}
                  isRequired={corporateNameField.isRequired}
                  isDisabled={!corporateNameField.editable}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_COPORATE_NAME')}
                />
              </div>
            )}
          </div>
          <div className="row m-0">
            {preferredLanguageField && preferredLanguageField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_PREFERRED_LANGUAGE')}
                  onChangeSelect={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                    setPreferredLanguage(e.target.value)
                  }
                  value={preferredLanguage}
                  selectOptions={preferredLanguageOptions}
                  type="select"
                  endAdornment={
                    !preferredLanguageField.editable && (
                      <img src={getIconUrl('Disable')} alt="disabled-preferred-language" />
                    )
                  }
                  isRequired={preferredLanguageField.isRequired}
                  isDisabled={!preferredLanguageField.editable}
                  placeholderToLabel
                  emptyMessage={t('SS_PERSONAL_REQUIRED_PREFERRED_LANGUAGE')}
                />
              </div>
            )}
          </div>
          <div className="row m-0">
            {contactNameField && contactNameField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_EMERGENCY_NAME')}
                  value={emergencyContactName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmergencyContactName(e.target.value)}
                  endAdornment={
                    !contactNameField.editable && <img src={getIconUrl('Disable')} alt="disabled-contact-name" />
                  }
                  isRequired={contactNameField.isRequired}
                  maxLength={contactNameField.maxLength}
                  isDisabled={!contactNameField.editable}
                  placeholderToLabel
                  multiline={isMobile}
                  errorMessage={emergencyNameError}
                  emptyMessage={t('SS_PERSONAL_REQUIRED_EMERGENCY_NAME')}
                />
              </div>
            )}
            {contactNumberField && contactNumberField.isVisible && (
              <div className="col-sm-12 p-0 col-md-6">
                <CustomInput
                  label={t('SS_PERSONAL_EMERGENCY_NUMBER')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onFilterPhoneRegex(
                      e.target.value,
                      setEmergencyContactPhone,
                      contactNumberField.isRequired,
                      constants.EMERGENCY_NUMBER
                    )
                  }
                  value={emergencyContactPhone}
                  endAdornment={
                    !contactNumberField.editable && <img src={getIconUrl('Disable')} alt="disabled-contact-number" />
                  }
                  maxLength={contactNumberField.maxLength}
                  isRequired={contactNumberField.isRequired}
                  isDisabled={!contactNumberField.editable}
                  placeholderToLabel
                  errorMessage={emergencyNumberError}
                  emptyMessage={t('SS_PERSONAL_REQUIRED_EMERGENCY_NUMBER')}
                />
              </div>
            )}
          </div>
        </div>
        <p className="card-info-personal__title">{t('SS_PERSONAL_PHONE')}</p>
        <i className="personal-at-least">{t('SS_PERSONAL_AT_LEAST')}</i>
        <div className="row m-0">
          {mobilePhoneField && mobilePhoneField.isVisible && (
            <div className="col-sm-12 p-0 col-md-6">
              <CustomInput
                label={t('SS_PERSONAL_MOBILE_NUMBER')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onFilterPhoneRegex(
                    e.target.value,
                    setMobilePhone,
                    mobilePhoneField.isRequired,
                    constants.MOBILE_PHONE
                  )
                }
                endAdornment={
                  !mobilePhoneField.editable && <img src={getIconUrl('Disable')} alt="disabled-mobile-phone" />
                }
                value={mobilePhone}
                errorMessage={mobilePhoneError}
                maxLength={mobilePhoneField.maxLength}
                isRequired={mobilePhoneField.isRequired}
                isDisabled={!mobilePhoneField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_MOBILE_PHONE')}
              />
            </div>
          )}

          {homePhoneField && homePhoneField.isVisible && (
            <div className="col-sm-12 p-0 col-md-6">
              <CustomInput
                label={t('SS_PERSONAL_HOME_NUMBER')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onFilterPhoneRegex(e.target.value, setHomePhone, homePhoneField.isRequired, constants.PHONE)
                }
                endAdornment={!homePhoneField.editable && <img src={getIconUrl('Disable')} alt="disabled-home-phone" />}
                value={homePhone}
                errorMessage={homePhoneError}
                maxLength={homePhoneField.maxLength}
                isRequired={homePhoneField.isRequired}
                isDisabled={!homePhoneField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_HOME_PHONE')}
              />
            </div>
          )}
        </div>

        <p className="card-info-personal__title">{t('SS_PERSONAL_MAILING_CONTACT')}</p>
        <div className="row m-0">
          {numberNameField && numberNameField.isVisible && (
            <div className="col-sm-12 p-0 col-md-12">
              <CustomInput
                label={t('SS_PERSONAL_NUMBER_NAME')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setNumberName, numberNameConfig)
                }
                value={numberName}
                endAdornment={
                  !numberNameField.editable && <img src={getIconUrl('Disable')} alt="disabled-first-name" />
                }
                placeholder={t('SS_PERSONAL_NUMBER_NAME_PLACEHOLDER')}
                maxLength={numberNameField.maxLength}
                isRequired={numberNameField.isRequired}
                isDisabled={!numberNameField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_NUMBER_NAME')}
                errorMessage={numberNameError}
              />
            </div>
          )}

          {addressLine1Field && addressLine1Field.isVisible && (
            <div className="col-sm-12 p-0 col-md-12">
              <CustomInput
                label={t('SS_PERSONAL_ADDRESS_LINE_1')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setAddressLine1, addressLine1Config)
                }
                value={addressLine1}
                endAdornment={
                  !addressLine1Field.editable && <img src={getIconUrl('Disable')} alt="disabled-first-name" />
                }
                placeholder={t('SS_PERSONAL_ADDRESS_LINE_1_PLACEHOLDER')}
                maxLength={addressLine1Field.maxLength}
                isRequired={addressLine1Field.isRequired}
                isDisabled={!addressLine1Field.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_ADDRESS_LINE_1')}
                errorMessage={addressLine1Error}
              />
            </div>
          )}
          {addressLine2Field && addressLine2Field.isVisible && (
            <div className="col-sm-12 p-0 col-md-12">
              <CustomInput
                label={t('SS_PERSONAL_ADDRESS_LINE_2')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setAddressLine2, addressLine2Config)
                }
                value={addressLine2}
                endAdornment={
                  !addressLine2Field.editable && <img src={getIconUrl('Disable')} alt="disabled-last-name" />
                }
                placeholder={t('SS_PERSONAL_ADDRESS_LINE_2_PLACEHOLDER')}
                maxLength={addressLine2Field.maxLength}
                isRequired={addressLine2Field.isRequired}
                isDisabled={!addressLine2Field.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_ADDRESS_LINE_2')}
                errorMessage={addressLine2Error}
              />
            </div>
          )}
        </div>
        <div className="row m-0">
          {cityField && cityField.isVisible && (
            <div className="col-sm-12 p-0 col-md-6">
              <CustomInput
                label={t('SS_PERSONAL_CITY')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setCity, cityConfig)
                }
                value={city}
                endAdornment={!cityField.editable && <img src={getIconUrl('Disable')} alt="disabled-last-name" />}
                placeholder={t('SS_PERSONAL_CITY_PLACEHOLDER')}
                maxLength={cityField.maxLength}
                isRequired={cityField.isRequired}
                isDisabled={!cityField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_CITY')}
                errorMessage={cityError}
              />
            </div>
          )}
          {postalCodeField && postalCodeField.isVisible && (
            <div className="col-sm-12 p-0 col-md-6">
              <CustomInput
                label={t('SS_PERSONAL_POST_CODE')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setPostalCode, postalCodeConfig)
                }
                value={postalCode}
                endAdornment={
                  !postalCodeField.editable && <img src={getIconUrl('Disable')} alt="disabled-first-name" />
                }
                placeholder={t('SS_PERSONAL_POST_CODE_PLACEHOLDER')}
                maxLength={postalCodeField.maxLength}
                isRequired={postalCodeField.isRequired}
                isDisabled={!postalCodeField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_POST_CODE')}
                errorMessage={postalCodeError}
              />
            </div>
          )}
          {countryField && countryField.isVisible && (
            <div className="col-sm-12 p-0 col-md-6">
              <CustomInput
                label={t('SS_PERSONAL_COUNTRY')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeInputValue(e.target.value, setCountry, countryConfig)
                }
                value={country}
                endAdornment={!countryField.editable && <img src={getIconUrl('Disable')} alt="disabled-first-name" />}
                placeholder={t('SS_PERSONAL_COUNTRY_PLACEHOLDER')}
                maxLength={countryField.maxLength}
                isRequired={countryField.isRequired}
                isDisabled={!countryField.editable}
                placeholderToLabel
                emptyMessage={t('SS_PERSONAL_REQUIRED_COUNTRY')}
                errorMessage={countryError}
              />
            </div>
          )}
        </div>

        <div className="card-info-personal__button">
          <button onClick={onSaveButtonClick} disabled={checkDisableSaveBtn()}>
            {t('SS_SAVE')}
          </button>
        </div>
      </Card>
      <ConfirmModal
        openModal={openConfirmSave}
        handleOpenModal={setOpenConfirmSave}
        displayMessage={
          <>
            <div>{t('SS_PERSONAL_CONFIRM_MESS')}</div>
            <div
              className="dialog-logout__note"
              dangerouslySetInnerHTML={{
                __html: t('SS_PERSONAL_POLICY_NOTE', {
                  term: membershipDetails?.endPoints?.termsAndConditionUrl || '#',
                  dataPrivacy: membershipDetails?.endPoints?.privacyPolicyUrl || '#',
                }),
              }}
            />
          </>
        }
        confirmLabel={t('SS_YES')}
        cancelLabel={t('SS_NO')}
        confirmAction={handleSave}
      />
    </div>
  );
};

export default PersonalInfo;
