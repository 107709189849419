import { CardUtil } from "types/EzyPay/Card";
import { EzyPayInfo } from "types/EzyPay/EzyPayInfo";
import { PayToUtil } from "types/EzyPay/PayTo";

class EzyPay {
  private receiver: Window;

  constructor(receiver?: Window) {
    this.receiver = receiver!;
  }

  sendPayment(
    event: MouseEvent,
    type: string,
    isAgreesTerm: boolean,
    refreshIframe: () => void,
  ): Promise<any> {
    this.receiver.postMessage(
      { actionType: type },
      window._env.EZYPAY_FRAME_URL,
    );
    return new Promise((resolve) =>
      this.retrievePaymentTokenCreated(
        event,
        resolve,
        isAgreesTerm,
        refreshIframe,
      ),
    );
  }

  private retrievePaymentTokenCreated(
    event: MouseEvent,
    resolve: any,
    isAgreesTerm: boolean,
    refreshIframe: () => void,
  ) {
    window.addEventListener("message", (event) => {
      if (event.data.includes && !event.data.includes("iFrameSizer")) {
        const response = JSON.parse(event.data);
        if (response.type === "success") {
          refreshIframe();
        }
        if (response?.data?.type) {
          resolve(response.data);
        }
        if (response.error) {
          resolve(response);
        }
      }
    });
  }

  static preparePayload(ezyPayInfo: EzyPayInfo, membershipDetails: any) {
    const payload = {
      clubId: membershipDetails.clubId,
      membershipNumber: membershipDetails.memberFirstId,
      countryCode: membershipDetails.countryCode,
      PaymentProvider: "EZ",
    };
    if (ezyPayInfo.card) {
      const { card } = ezyPayInfo;
      return {
        ...payload,
        EzyPayPaymentMethod: {
          CardHolderName: card.accountHolderName,
          CardBin: card.first6,
          CardSummary: card.last4,
          CardType: CardUtil.getCardType(card),
          CardSubType: "",
          AccountType: ezyPayInfo.type,
          ExpiryDate: CardUtil.getExpireDate(card).replace("/", ""),
          BsbNumber: "",
          PayIdViaEmail: "",
          PayIdViaPhoneNumber: "",
          RecurringDetailReference: ezyPayInfo.paymentMethodToken,
        },
      };
    }

    if (ezyPayInfo.bank) {
      const { bank } = ezyPayInfo;
      return {
        ...payload,
        EzyPayPaymentMethod: {
          CardHolderName: bank.accountHolderName,
          CardBin: "",
          CardSummary: bank.last4,
          CardType: ezyPayInfo.type,
          CardSubType: "",
          AccountType: ezyPayInfo.type,
          ExpiryDate: "",
          BsbNumber: bank.bankNumber,
          PayIdViaEmail: "",
          PayIdViaPhoneNumber: "",
          RecurringDetailReference: ezyPayInfo.paymentMethodToken,
        },
      };
    }

    if (ezyPayInfo.payTo) {
      const { payTo } = ezyPayInfo;
      return {
        ...payload,
        EzyPayPaymentMethod: {
          CardHolderName: payTo.accountHolderName,
          CardBin: "",
          CardSummary: PayToUtil.getDomesticNumber(payTo),
          CardType: ezyPayInfo.type,
          CardSubType: "",
          AccountType: ezyPayInfo.type,
          ExpiryDate: "",
          BsbNumber: PayToUtil.getBsbNumber(payTo),
          PayIdViaEmail: PayToUtil.getPayIdEmail(payTo),
          PayIdViaPhoneNumber: PayToUtil.getPayIdPhoneNumber(payTo),
          RecurringDetailReference: ezyPayInfo.paymentMethodToken,
        },
      };
    }
  }
}

export default EzyPay;
