import Header from 'components/Header';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { themeActions, paymentActions } from 'redux/actionCreators';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';
import AppRoute from './AppRoute';

const globalErrorMapping = [
  'REQUEST_ALREADY_PROCESSED',
  'AMOUNT_NOT_VALID',
  'Member_Addon_Amount_Not_Match',
  'ONLINE_PAYMENT_METHOD_IS_EMPTY',
  'ONLINE_PAYMENT_METHOD_IS_NOT_ALLOWED',
  'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_CREDIT_METHOD',
  'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_DEBIT_METHOD',
  'MEMBER_INFO_IS_NOT_EXISTED',
  'MEMBER_INFO_IS_NOT_IN_AVAILABLE_STATE',
  'PAYMENT_RESPONSE_DATA_IS_INVALID',
  'PAYMENT_METHOD_NOT_VALID',
  'PAYMENT_UPGRADED_MEMBERSHIP_AMOUNT_IS_NOT_MATCH',
];

const globalPersonalErrorMapping = [
  'CHANGE_PASSWORD_EMAIL_NOT_FOUND',
  'CHANGE_PASSWORD_REQUIRED_CURRENT_PASSWORD',
  'CHANGE_PASSWORD_REQUIRED_NEW_PASSWORD',
  'CHANGE_PASSWORD_NOT_MATCH_REQUIREMENT',
  'CHANGE_PASSWORD_NOT_MATCH_CURRENT_PASSWORD',
  'CHANGE_PASSWORD_NOT_FOUND',
  'CHANGE_PASSWORD_NOT_PASS_HISTORY_CHECK',
];

const LayoutInner = () => {
  const [changePasswordStatus, setChangePasswordStatus] = useState({ status: '', GUID: Date.now() });

  const { t } = useTranslation();
  const personalError = useTypedSelector((state) => state.personal.error);
  const membershipError = useTypedSelector((state) => state.membership.error);
  const isGettingTheme = useTypedSelector((state) => state.theme.isLoading);
  const { enqueueSnackbar } = useSnackbar();
  const { globalError, globalErrorGUID } = useTypedSelector((state) => state.payment);
  const hasChangeRef = useRef(false);

  const dispatch = useDispatch();

  const { getTheme } = themeActions;
  const { loadDisabledUseEzyPay } = paymentActions;
  const getThemeAction = () => dispatch(getTheme());

  const renderGlobalNotifyTitle = (type: string = 'INTERNAL') => {
    switch (type) {
      case 'MEMBERSHIP':
        return t('SS_MEMBERSHIP_ERROR');
      case 'PAYMENT':
        return t('SS_PAYMENT_ARREARS_ERROR');
      case 'PERSONAL':
        return t('SS_PERSONAL_ERROR');
      default:
        return t('SS_INTERNAL_ERRORS');
    }
  };

  useEffect(() => {
    getThemeAction();
  }, []);

  useEffect(() => {
    dispatch(loadDisabledUseEzyPay());
  }, []);

  useEffect(() => {
    if (
      membershipError ||
      (changePasswordStatus.status && changePasswordStatus.status !== 'SUCCESS') ||
      personalError
    ) {
      emitNotify(
        enqueueSnackbar,
        renderGlobalNotifyTitle(
          (membershipError && 'MEMBERSHIP') || ((changePasswordStatus || personalError) && 'PERSONAL')
        ),
        membershipError ||
          (globalPersonalErrorMapping.includes(changePasswordStatus.status) && t(changePasswordStatus.status)) ||
          personalError,
        'error'
      );
    }
  }, [changePasswordStatus.GUID, membershipError, personalError]);

  useEffect(() => {
    if (globalError) {
      emitNotify(
        enqueueSnackbar,
        renderGlobalNotifyTitle('PAYMENT'),
        (globalErrorMapping.includes(globalError) && t(globalError)) || globalError,
        'error'
      );
    }
  }, [globalErrorGUID]);

  if (isGettingTheme) {
    return null;
  }

  return (
    <>
      <Header hasChangeRef={hasChangeRef} />
      <AppRoute hasChangeRef={hasChangeRef} setChangePasswordStatus={setChangePasswordStatus} />
    </>
  );
};

export default LayoutInner;
