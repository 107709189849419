import IframeResizer from "iframe-resizer-react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import format from "number-format.js";

import useEzyPayFrame from "./hooks/useEzyPayFrame";
import { useTypedSelector } from "redux/configureStore";
import PERSONAL_CONSTANTS from "constants/personalConstant";
import { selectConfigByKey } from "selectors/config";

import { useEffect, useRef, useState } from "react";

import EzyPayFee from "components/EzyPayFee";
import { EzyPayInfo } from "types/EzyPay/EzyPayInfo";

import { MEMBERSHIP_URL } from "utility/routesURL";
import EzyPay from "utility/ezyPay";

import { editPaymentService } from "services"; 
import { useDispatch } from "react-redux";
import { updateEzyPayInfo } from "redux/actionCreators/payment";
import emitNotify from "utility/emitNotify";

type EzyPayFrameProps = {
  paymentMethodId: string;
  updateDisablePaymentOption: (disable: boolean) => void;
  disablePaymentOption: boolean;
};

const EzyPayFrame = (props: EzyPayFrameProps) => {
  const { paymentMethodId, updateDisablePaymentOption, disablePaymentOption } =
    props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [error, setShowError] = useState<{
    agreement: boolean;
    submitEditPayment: boolean;
  }>({
    agreement: false,
    submitEditPayment: false,
  });
  const [isAgree, setIsAgree] = useState<boolean>();
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const { ezyPayInfo } = useTypedSelector(state => state.payment);
  const agreeRef = useRef<any>(null);

  const {
    isIframeAdjustHeight,
    messageData,
    iframeSrc,
    isLoadingIFrame,
    retrieveToken,
    onResized,
    onLoadIframe,
    refreshIframe
  } = useEzyPayFrame({ paymentMethodId, agreeRef });

  const { t } = useTranslation();

  const { totalRecurringFee } = useTypedSelector((state) => state.membership);
 
  const currencyFormat = useTypedSelector((state) =>
    selectConfigByKey(state, PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX),
  );

  const handleSubmit = (event: any) => {
    if (agreeRef.current) {
      setShowError({
        ...error,
        agreement: !agreeRef.current.checked,
        submitEditPayment: false,
      });
      retrieveToken(event, agreeRef.current.checked);
    }
  };

  useEffect(() => {
    if(!ezyPayInfo || ezyPayInfo.type === "error") {
      return
    }

    processEditPayment(ezyPayInfo);

  }, [ezyPayInfo])

  const processEditPayment = async (ezyPayInfo: EzyPayInfo) => {
    try {
      updateDisablePaymentOption(true);
      const payload = EzyPay.preparePayload(ezyPayInfo, membershipDetails);
      const response = await editPaymentService(
        membershipDetails.memberId,
        payload,
      );
      const { data } = response;
      if (data.isSuccess) {
        emitNotify(
          enqueueSnackbar,
          t("SS_NOTIFICATION_PAYMENT_AND_CREDITS"),
          t("SS_NOTIFICATION_PAYMENT_UPDATE_SAVING"),
          "checked",
        );
        history.push(MEMBERSHIP_URL);
      }
    } catch (_) {
      setShowError({ ...error, submitEditPayment: true });
      refreshIframe(true);
    } finally {
      updateDisablePaymentOption(false);
      dispatch(updateEzyPayInfo(null));
    }
  };

  const handleOnChangeAgree = () => {
    if (agreeRef?.current?.checked) {
      setIsAgree(!agreeRef.current.checked);
      setShowError({ ...error, agreement: false });
    }
  };

  useEffect(() =>  {
    if(error.submitEditPayment) {
      setShowError({
        ...error, 
        submitEditPayment: false
      })
    }
  }, [paymentMethodId])

  useEffect(() => {
    updateDisablePaymentOption(isLoadingIFrame || isIframeAdjustHeight);
  }, [isLoadingIFrame, isIframeAdjustHeight, updateDisablePaymentOption]);

  return (
    <>
      {error.submitEditPayment && (
        <Alert
          icon={false}
          variant="outlined"
          className="iframe__frame-error-submit error-wrapper"
          severity="error"
        >
          {t("FAIL_CHANGE_PAYMENT")}
        </Alert>
      )}
      {(isLoadingIFrame || isIframeAdjustHeight) && (
        <CircularProgress className="iframe__frame-loading loading" />
      )}
      {iframeSrc && (
        <div className="iframe__frame-wrapper">
          <IframeResizer
            id="ezypay-iframe"
            className="iframe__frame-ezypay"
            onResized={onResized}
            onLoad={onLoadIframe}
            onMessage={onResized}
            style={{
              display: !isLoadingIFrame ? "block" : "none",
              height:
                (!isLoadingIFrame && messageData ? messageData.height : 30) +
                "px",
            }}
            checkOrigin={false}
            src={iframeSrc}
            frameBorder={0}
          />
          <div className="iframe__frame-bottom">
            <EzyPayFee />
            <div className="iframe__frame-error">
              <FormControlLabel
                className="iframe__frame-term"
                control={
                  <Checkbox
                    name="check"
                    onChange={handleOnChangeAgree}
                    inputRef={agreeRef}
                  />
                }
                label={
                  <>
                    {t("AGREEMENT_SAVE_PAYMENT", {
                      currency: membershipDetails.currencyCode,
                      recurringFeeAmount: format(
                        currencyFormat!,
                        totalRecurringFee,
                      ),
                    })}
                    <span className="is-required">*</span>
                  </>
                }
              />
              {error.agreement && !isAgree && (
                <span className="iframe__frame-error-text">
                  {t("ERROR_NO_AGREE")}
                </span>
              )}
            </div>
          </div>
          <button
            className="iframe__frame-verify btn btn-primary btn--aside btn-effect"
            disabled={
              isLoadingIFrame || isIframeAdjustHeight || disablePaymentOption
            }
            onClick={handleSubmit}
          >
            {t("SS_SUBMIT")}
          </button>
        </div>
      )}
    </>
  );
};

export default EzyPayFrame;
