import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

import { useTypedSelector } from "redux/configureStore";

import PERSONAL_CONSTANTS from "constants/personalConstant";

import { selectConfigByKey } from "selectors/config";
import { AddonInterface } from "components/Addon/types";

import { generateUrlEzyPayCheckOut } from "services/payment";
import { DOMAIN_URL } from "constants/baseConstants";
import { showLoading } from "utility/sweetAlert";
import { useQuery } from "hooks";

const AMOUNT_KEY = uuidv4();

function usePaymentHandler(amount: number, addons?: AddonInterface[]) {
  const [agreement, setAgreement] = useState({
    term: false,
    savePayment: false,
  });
  const [showError, setShowError] = useState({
    missingAgreement: false,
    errorPayment: false,
  });
  const [amountPayment, setAmountPayment] = useState(amount);
  const query = useQuery();
  const amountKey = query.get("amount_key") as string;

  useEffect(() => {
    if (query.get("payment_fail") === "true") {
      setAgreement({
        savePayment: query.get("save_payment") === "true",
        term: true,
      });
      const storedAmount = Number(localStorage.getItem(amountKey));
      if (storedAmount) {
        setAmountPayment(storedAmount);
      }
    }
  }, [query]);

  const { currencyCode, currencyFormat } = useTypedSelector((state) => ({
    currencyCode: selectConfigByKey(state, PERSONAL_CONSTANTS.CURRENCY),
    currencyFormat: selectConfigByKey(
      state,
      PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX,
    ),
  }));

  const { membershipDetails, onlinePaymentMethod, totalRecurringFee } =
    useTypedSelector((state) => state.membership);

  const showSaveNewPaymentMethod = useMemo(
    () =>
      [PERSONAL_CONSTANTS.EFT, PERSONAL_CONSTANTS.CARD].includes(
        membershipDetails.paymentMethod,
      ) && onlinePaymentMethod === PERSONAL_CONSTANTS.ANY,
    [membershipDetails, onlinePaymentMethod],
  );

  const handleOnChangeAgreement = (event: any) => {
    setAgreement({
      ...agreement,
      [event.target.name]: event.target.checked,
    });
    setShowError((prev) => ({ ...prev, missingAgreement: false }));
  };

  const handleRedirectToEzyPay = async () => {
    showLoading();
    const paymentURlCancel = addons?.length ? "addon-payment" : "payment";
    try {
      if (localStorage.getItem(amountKey)) {
        localStorage.removeItem(amountKey);
      }
      localStorage.setItem(AMOUNT_KEY, amountPayment.toString());
      const response = await generateUrlEzyPayCheckOut({
        Value: Number(amountPayment),
        Currency: currencyCode,
        CustomerId: membershipDetails.customerId,
        MemberId: membershipDetails.memberId,
        MembershipNumber: membershipDetails.memberFirstId,
        PaymentProvider: "EZ",
        PartyId: membershipDetails.partyID,
        SuccessUrl: DOMAIN_URL + `/membership?amount_key=${AMOUNT_KEY}`,
        IsSavePayment: agreement.savePayment,
        CancelUrl:
          DOMAIN_URL +
          `/${paymentURlCancel}?payment_fail=true&save_payment=${agreement.savePayment}&amount_key=${AMOUNT_KEY}`,
        AddonIds: (addons || []).map((addon) => addon.id),
      });
      if (response.checkoutUrl) {
        window.location.replace(response.checkoutUrl);
      }
    } catch (error) {
      setShowError((prev) => ({ ...prev, errorPayment: true }));
      console.error("Error while making payment:", error);
      Swal.close();
    }
  };

  const handleSubmit = () => {
    if (!agreement.term) {
      setShowError((prev) => ({ ...prev, missingAgreement: true }));
      return;
    }
    handleRedirectToEzyPay();
  };

  return {
    agreement,
    showError,
    currencyCode,
    membershipDetails,
    currencyFormat,
    totalRecurringFee,
    amountPayment,
    showSaveNewPaymentMethod,
    handleOnChangeAgreement,
    handleSubmit,
    setAmountPayment,
  };
}

export default usePaymentHandler;
