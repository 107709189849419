import moment from "moment";

import { MM_YY_FORMAT } from "constants/dateContants";

export interface Card {
  accountHolderName: string;
  expiryYear: string;
  expiryMonth: string;
  type: string;
  last4: string;
  first6: string;
  countryCode: string;
}

export const CardUtil = {
  getCardNumber(card: Card) {
    return card.first6 + "*".repeat(6) + card.last4;
  },
  getExpireDate(card: Card) {
    return moment(
      card.expiryMonth + "/" + card.expiryYear,
      MM_YY_FORMAT,
    ).format(MM_YY_FORMAT);
  },
  getCardType(card: Card) {
    const { type } = card;
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  },
};
