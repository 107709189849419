import { PlanProps, PreviewPaymentSummaryProps, StepClubProps } from 'screens/UpgradeMemberShip/types';
import { ARREARS_PREVIEW, GET_CLUBS_BY_BRAND, GET_PLAN_BY_CLUB, GET_PREVIEW_PAYMENT_SUMMARY } from './apis';
import { baseApi } from './base';

export async function getClubs(brandCode: string): Promise<StepClubProps> {
  const res = await baseApi.get(GET_CLUBS_BY_BRAND.replace('{{brandCode}}', brandCode));
  return res.data;
}

export const getPlanData = async (clubId: string, addonIds: number[]): Promise<PlanProps> => {
  const res = await baseApi.post(GET_PLAN_BY_CLUB.replace('{{clubId}}', clubId), {
    addonIds,
  });
  return res.data;
};

export const arrearPreviews = async (body: any): Promise<any> => {
  const { data } = await baseApi.post(ARREARS_PREVIEW, body);
  return data;
};
interface PreviewDataParamProps {
    clubId: number;
    planId?: number;
    reciprocalAccessCode?: string;
    clusterId?: number;
    membershipLength: number;
    startedDate: Date | null | string;
    addonClassIds: number[];
    addonIds?: number[];
    promotionCode?: string| null;
}

export const getPreviewData = async (data: PreviewDataParamProps): Promise<PreviewPaymentSummaryProps> => {
  const res = await baseApi.post(GET_PREVIEW_PAYMENT_SUMMARY, data);
  return res.data;
};
