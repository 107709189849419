import COMMON_CONSTANTS from './commonContants';

const PERSONAL_CONSTANTS = {
  ...COMMON_CONSTANTS,
  PAYMENT_DATA: "paymentData",
  CARD: "Card",
  CREDIT_CARD: "Credit Card",
  DIRECT_DEBIT: "Direct Debit",
  CONVERSION: "Conversion",
  CC: "CC",
  DD: "DD",
  EFT: "EFT",
  MD: "MD",
  PARES: "PaRes",
  ACCESS_TOKEN: "accessToken",
  TENANT_ID: "tenantId",
  SUBTENANT_ID: "subTenantId",
  TOKEN: "token",
  LANGUAGE: "language",
  ADYEN_DROPIN_CLASS: "adyen-checkout__dropin",
  MOBILE_PHONE: "MobileTelephone",
  PHONE: "HomeTelephone",
  EMERGENCY_NUMBER: "EmergencyNumber",
  PREFIX_VALIDATION_REGEX: "Jol.Member.{{PhoneType}}.Regex",
  VALIDATION_REGEX_DEFAULT: "Validation.RegularExpressions.Asia.Telephone",
  DATE_REGEX: "CultureOverride.ShortDatePattern",
  CURRENCY_REGEX: "Jol.Adyen.CurrencyCode",
  CURRENCY_FORMAT_REGEX: "Jol.Adyen.CurrencyCodeFormat",
  JOL_EMAIL_REGEX: "Jol.Member.EmailAddress.Regex",
  JOL_FIRST_NAME_REGEX: "Jol.Member.FirstName.Regex",
  JOL_LAST_NAME_REGEX: "Jol.Member.LastName.Regex",
  EMERGENCY_NAME_REGEX: "Member.EmergencyContactName.Regex",
  PASSWORD_REGEX: "Idp.Member.PasswordRegex",
  FIRST_NAME_KEY: "UpdatePersonalDetail.FirstName",
  LAST_NAME_KEY: "UpdatePersonalDetail.LastName",
  EMAIL_KEY: "UpdatePersonalDetail.EmailAddress",
  GENDER_KEY: "UpdatePersonalDetail.Gender",
  BIRTH_DATE_KEY: "UpdatePersonalDetail.BirthDate",
  MOBILE_PHONE_KEY: "UpdatePersonalDetail.MobilePhone",
  HOME_PHONE_KEY: "UpdatePersonalDetail.HomePhone",
  CONTACT_NAME_KEY: "UpdatePersonalDetail.ContactName",
  CONTACT_NUMBER_KEY: "UpdatePersonalDetail.ContactNumber",
  CORPORATE_NAME_KEY: "UpdatePersonalDetail.CorporateName",
  NUMBER_NAME_KEY: "UpdatePersonalDetail.NumberName",
  NUMBER_NAME_REGEX_KEY: "Jol.Member.AddressLine1.Regex",
  ADDRESS_LINE_1_KEY: "UpdatePersonalDetail.AddressLine1",
  ADDRESS_LINE_1_REGEX_KEY: "Jol.Member.AddressLine1.Regex",
  ADDRESS_LINE_2_KEY: "UpdatePersonalDetail.AddressLine2",
  ADDRESS_LINE_2_REGEX_KEY: "Jol.Member.AddressLine2.Regex",
  POST_CODE_KEY: "UpdatePersonalDetail.PostalCode",
  POST_CODE_REGEX_KEY: "Jol.Member.Postcode.Regex",
  CITY_KEY: "UpdatePersonalDetail.City",
  CITY_REGEX_KEY: "Jol.Member.City.Regex",
  COUNTRY_KEY: "UpdatePersonalDetail.Country",
  PREFERRED_LANGUAGE_KEY: "UpdatePersonalDetail.PreferredLanguage",
  SHOW_RECENT_ACTIVITY: "SS.Member.ShowRecentActivity",
  SHOW_BROWSER_TYPE: "SS.Member.ShowBrowserType",
  SHOW_LOCATION: "SS.Member.ShowLocation",
  SS_GO_LIVE_DATE: "SS.GoLiveDate",
  SS_MAINTENANCE_MODE: "SS.MaintenanceMode",
  DATE_REGEX_BE_DEFAULT: "YYYY-MM-DD",
  SCHEME: "scheme",
  EZYPAY_PAY_TO: "Ezypay.PayToPayment",
  EZYPAY_MANDATE_TYPE: "Ezypay.PayToMandateTypeID",
  CURRENCY: "CurrencyCode",
  ANY: "Any",
};

export const BRAND_CODE_ALIAS: any = {
  '5E': 'Fivelements',
  FF: 'Fitness First',
  GR: 'Gravity Club',
  CF: 'Celebrity Fitness',
};

export const SESSION_STORAGE_NAMES = {
  ADDON_IDS: 'addonIds',
  AGREE_TNC: 'agreeTnC',
  PAYMENT_TYPE: 'paymentType',
  AMOUNT_VALUE: 'amountValue',
  SAVE_FOR_NEXT_RECURRING: 'saveForNextRecurring',
  LOCATION_IP: 'locationIp',
  PROCESS_PURPOSE: 'processPurpose',
  UPGRADED_MEMBERSHIP: 'upgradedMembership',
  UPGRADED_PREVIEW: 'upgradedPreview',
  PAYMENT_DATA: 'paymentData',
};

export const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD';
export const LOGIN_LOG_DATE_FORMAT = 'MMM D, YYYY';

export const VIRTUAL_ADDON_CODES = ['VX', 'VW'];

export default PERSONAL_CONSTANTS;
