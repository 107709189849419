import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Select } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';

interface InputProps {
  label: string;
  className?:string,
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  isDisabled?: boolean;
  value: string;
  type?: string;
  selectOptions?: { value: string; name: string }[];
  isRequired?: boolean;
  hidden?: boolean;
  errorMessage?: string;
  placeholder?: string;
  placeholderToLabel?: boolean;
  maxLength?: number;
  formatForDate?: string;
  defaultFormatForDate?: string;
  multiline?: boolean;
  emptyMessage: string;
  hideLabel?: boolean;
  componentConfig?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const CustomInput = (props: InputProps) => {
  const {
    className,
    label,
    endAdornment,
    startAdornment,
    isDisabled = false,
    value,
    selectOptions,
    type = 'text',
    isRequired = false,
    hidden,
    errorMessage,
    placeholder,
    placeholderToLabel = false,
    maxLength,
    formatForDate,
    defaultFormatForDate = 'DD/MM/YYYY',
    emptyMessage,
    multiline,
    hideLabel,
    componentConfig,
    onChange,
    onChangeSelect,
    onFocus,
    onBlur,
  } = props;
  const isEmptyInput = !value || value.length === 0;

  const renderBaseOnType = (type = 'text') => {
    switch (type) {
      case 'select':
        return (
          <Select
            className={className}
            native
            endAdornment={endAdornment}
            startAdornment={startAdornment}
            labelWidth={60}
            disabled={isDisabled}
            value={
              formatForDate && value.length === defaultFormatForDate.length
                ? moment(value, defaultFormatForDate).format(formatForDate)
                : value
            }
            placeholder={placeholder}
            onChange={onChangeSelect}
            inputProps={{
              id: `select-${label.replace(/\s/g, '')}`,
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            hidden={hidden}
            error={(isRequired && isEmptyInput) || !!errorMessage}
          >
            {selectOptions?.map((ot: { value: string; name: string }) => (
              <option key={`${ot.value}`} value={ot.value}>
                {ot.name}
              </option>
            ))}
          </Select>
        );
      default:
        return (
          <OutlinedInput
            id={`input-${label.replace(/\s/g, '')}`}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
            labelWidth={60}
            multiline={multiline}
            disabled={isDisabled}
            value={
              formatForDate && value.length === defaultFormatForDate.length
                ? moment(value, defaultFormatForDate).format(formatForDate)
                : value
            }
            placeholder={placeholder}
            onChange={onChange}
            type={type}
            inputProps={{
              maxLength: maxLength,
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            hidden={hidden}
            error={(isRequired && isEmptyInput) || !!errorMessage}
            classes={{ notchedOutline: 'notchedOutline', focused: 'focused' }}
            className={classNames({
              hideLabel: hideLabel,
            })}
            {...componentConfig}
          />
        );
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classNames({
        'hide-label': hideLabel,
        'animate-field': placeholderToLabel,
        'text-field': !placeholderToLabel,
        'disable-label': isDisabled,
      })}
    >
      {!hidden && (
        <InputLabel htmlFor="outlined-adornment-amount" error={(isRequired && isEmptyInput) || !!errorMessage}>
          {label}
          {isRequired && <span className="is-required">*</span>}
        </InputLabel>
      )}
      {renderBaseOnType(type)}
      {isRequired && isEmptyInput && !errorMessage && !isDisabled && <span className="empty-mess">{emptyMessage}</span>}
      {errorMessage && <span className="empty-mess">{errorMessage}</span>}
    </FormControl>
  );
};

export default CustomInput;
