import { useState } from "react";

import { useTypedSelector } from "redux/configureStore";
import { selectPaymentForEzyPay } from "selectors/membership";

import EzyPayFrame from "./EzyPayFrame";

import "./ezypay-payment.scss";

import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const EzyPayPayment = () => {
  const [disablePaymentOption, setDisablePaymentOption] = useState(false);
  const { t } = useTranslation();
  const paymentOptions = useTypedSelector((state) =>
    selectPaymentForEzyPay(state),
  );
  const [paymentSelected, setPaymentSelected] = useState(
    paymentOptions[1].paymentMethodTypeCode,
  );

  const handleChangePayment = (event: any) => {
    setPaymentSelected(event.target.value);
  };

  return (
    <div className="ezypay">
      <span className="ezypay__title">{t("SS_PAYMENT_INFORMATION")}</span>
      <FormControl variant="outlined" className="ezypay__payments">
        <InputLabel id="payment-config">{t("PAYMENT_CONFIG")}</InputLabel>
        <Select
          labelId="payment-config"
          id="payment-config-outlined"
          value={paymentSelected}
          onChange={handleChangePayment}
          label={t("PAYMENT_CONFIG")}
          disabled={disablePaymentOption}
        >
          {paymentOptions.map((payment) => (
            <MenuItem
              value={payment.paymentMethodTypeCode}
              key={payment.paymentMethodTypeCode}
            >
              {payment.planPaymentMethodTypeName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <EzyPayFrame
        paymentMethodId={paymentSelected}
        updateDisablePaymentOption={setDisablePaymentOption}
        disablePaymentOption={disablePaymentOption}
      />
    </div>
  );
};

export default EzyPayPayment;
