import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { getIconUrl } from "utility/helpers";

import { useTypedSelector } from "redux/configureStore";
import { selectPaymentPrimary } from "selectors/payment";

import {
  getAccountInfo,
  getExpireDate,
  getPaymentName,
  isCreditCard,
  isDirectDebit,
} from "utility/payment";

import "./ezy-payment-info.scss";
import { CASH } from "constants/paymentConstants";

const EzyPayPaymentInfo = () => {
  const { t } = useTranslation();
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const paymentPrimary = useTypedSelector((state) =>
    selectPaymentPrimary(state),
  );

  const isNoPayment = useMemo(
    () =>
      membershipDetails.memberStatus === "Suspended" ||
      !paymentPrimary.cardType,
    [membershipDetails, paymentPrimary],
  );

  if (membershipDetails.paymentMethod === CASH) {
    return (
      <div className="cash-wrapper">
        <span>{t("PAYMENT_CONFIG")}</span>
        <div className="cash-wrapper__content">
          <img src={getIconUrl(CASH)} alt="cash" />
          {membershipDetails.paymentMethod}
        </div>
      </div>
    );
  }

  const renderCard = () => {
    return (
      <>
        <div className="ezy-pay__body-row">
          <span>{paymentPrimary.cardType}</span>
          <span>{paymentPrimary.accountNumber}</span>
        </div>
        <div className="ezy-pay__body-row">
          <span>{t("EXPIRE_DATE")}</span>
          <span>{getExpireDate(paymentPrimary.expiryDate)}</span>
        </div>
      </>
    );
  };

  const renderDirectDebit = () => {
    return (
      <>
        <div className="ezy-pay__body-row">
          <span>{paymentPrimary.cardType}</span>
          <span>{getAccountInfo(paymentPrimary)}</span>
        </div>
        {paymentPrimary.issuerCode && (
          <div className="ezy-pay__body-row">
            <span>{t("BSB")}</span>
            <span>{paymentPrimary.issuerCode}</span>
          </div>
        )}
        {paymentPrimary.payToMandateStatus && (
          <div className="ezy-pay__body-row">
            <span>{t("M_STATUS")}</span>
            <span>{paymentPrimary.payToMandateStatus}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="ezy-pay">
      <div className="ezy-pay__header">
        <img src={getIconUrl("CardPayment")} alt="card-payment" />
        {!isNoPayment ? (
          <span>{getPaymentName(paymentPrimary)}</span>
        ) : (
          <span>{t("PAYMENT_NO_PAYMENT_METHOD_AVAILABLE")}</span>
        )}
      </div>
      {!isNoPayment && (
        <div
          className={classNames("ezy-pay__body", {
            "ezy-pay__body--master-card":
              paymentPrimary.cardType.toUpperCase() === "MASTERCARD",
            "ezy-pay__body--email": paymentPrimary.payIdViaEmail,
          })}
        >
          {isCreditCard(paymentPrimary) && renderCard()}
          {isDirectDebit(paymentPrimary) && renderDirectDebit()}
        </div>
      )}
    </div>
  );
};

export default EzyPayPaymentInfo;
