import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import { themeActionTypes as actionTypes } from '../actionTypes';
import { themeActions as actionCreators } from '../actionCreators';

import { getThemeService } from 'services';
import { convertScss } from 'utility/convertScssScript';
import { CDN_IMAGES_URL } from 'constants/baseConstants';

declare var window: any;
function* getTheme() {
  const { brandCode = '' } = yield select((state) => state.oidc.user.profile);
  const brandCodeLowerCase = brandCode.toLocaleLowerCase();
  try {
    const { data } = yield call(getThemeService, brandCodeLowerCase || 'ff');
    if (data && Object.keys(data).length !== 0) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      const favicon16 = document.createElement('link');
      const favicon32 = document.createElement('link');
      const appleTouchIcon = document.createElement('link');
      const themeUrl = data.themeUrl;

      const appleTouchIconUrl = CDN_IMAGES_URL.replace('{fileName}', `${brandCodeLowerCase}-apple-touch-icon.png`);
      const favicon16Url = CDN_IMAGES_URL.replace('{fileName}', `${brandCodeLowerCase}-favicon-16x16.png`);
      const favicon32Url = CDN_IMAGES_URL.replace('{fileName}', `${brandCodeLowerCase}-favicon-32x32.png`);

      appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
      appleTouchIcon.setAttribute('sizes', '180x180');
      appleTouchIcon.setAttribute('href', appleTouchIconUrl);

      favicon16.setAttribute('rel', 'icon');
      favicon16.setAttribute('type', 'image/png');
      favicon16.setAttribute('sizes', '16x16');
      favicon16.setAttribute('href', favicon16Url);

      favicon32.setAttribute('rel', 'icon');
      favicon32.setAttribute('type', 'image/png');
      favicon32.setAttribute('sizes', '32x32');
      favicon32.setAttribute('href', favicon32Url);

      style.setAttribute('type', 'text/css');
      style.setAttribute('rel', 'stylesheet/scss');
      style.setAttribute("href", themeUrl);
      head.prepend(appleTouchIcon, favicon16, favicon32, style);

      yield convertScss(document, window);
      yield put(actionCreators.getThemeSuccess(themeUrl));
    }
    yield put(actionCreators.getThemeFail({ error: null }));
  } catch (error) {
    console.log("Warning: Theme load failed!", error)
  }
}

function* watchTheme() {
  yield takeLatest(actionTypes.GET_THEME_BY_BRAND, getTheme);
}

export default function* themeSaga() {
  yield all([fork(watchTheme)]);
}
