import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { CardIcon, SecureSSLIcon } from 'components/Icons';
import { ExistCard } from 'components/MakePayment';
import ConfirmModal from 'components/ModalConfirm';
import { ANSWER } from 'constants/commonContants';
import { DONE_MAKE_PAYMENT_TYPES, PAYMENT_ERRORS, PAYMENT_PURPOSES } from 'constants/paymentConstants';
import PERSONAL_CONSTANTS, {
  BRAND_CODE_ALIAS,
  SESSION_STORAGE_NAMES,
  SYSTEM_DATE_FORMAT,
} from 'constants/personalConstant';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import format from 'number-format.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { paymentActions } from 'redux/actionCreators';
import { setNotification } from 'redux/actionCreators/notification';
import { useTypedSelector } from 'redux/configureStore';
import i18n from 'translations/i18n';
import { translations } from 'utility/adyenTranslations';
import { CheckExpiredCard } from 'utility/checkExpiredCard';
import emitNotify from 'utility/emitNotify';
import { elementExistInDom } from 'utility/helpers';
import { MEMBERSHIP_URL } from 'utility/routesURL';

interface VerifyThreeDSAddonBody {
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: any;
  };
  isVerifyForPayment?: boolean;
  addonIds: any;
  upgradedMembership: any;
}

const PaymentMerchant = (props: any) => {
  const validCardForMakePayment = ['CC', 'Credit Card', 'Conversion', 'EFT'];
  const restrictCards = ['BCA Local Card'];
  const { t } = useTranslation();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const {
    showExistCard,
    setOpenConfirmPayExistCard,
    isHideNextRecurring,
    saveForNextCheckboxRef,
    setAgreeUseRegisterCard,
    agreeUseRegisterCard,
    showRegisterCardFragment,
    isAgreePolicies,
    isCheckAffirmativeSubcriptionTerms,
    setIsCheckAffirmativeSubcriptionTerms,
    recurring,
  } = props;
  const { error } = useTypedSelector((state) => state.payment);
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const { payment, previousPayment } = membershipDetails;
  const [isCheckSaveForNextRecurring, setIsCheckSaveForNextRecurring] = useState(false);
  const saveForNextRecurring = sessionStorage.getItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);

  const { regexs } = useTypedSelector((state) => state.personal);
 
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  useEffect(() => {
    setIsCheckSaveForNextRecurring(saveForNextRecurring === ANSWER.YES || isCheckSaveForNextRecurring);
  }, [saveForNextRecurring]);

  const handleAgreeUsingRegisterCard = (e: any) => {
    setAgreeUseRegisterCard({
      error: false,
      isAgree: e.target.checked,
    });
  };

  const handleCheckSaveForNextRecurring = (e: any) => {
    setIsCheckSaveForNextRecurring(e.target.checked);
  };

  const handleCheckAffirmativeSubcriptionTerms = (e: any) => {
    setIsCheckAffirmativeSubcriptionTerms(e.target.checked);
  };

  const openConfirm = () => {
    setOpenConfirmPayExistCard(true);
  };

  const renderError = () => {
    switch (error) {
      case PAYMENT_ERRORS.NETWORK_ERROR:
        return t('CONNECTION_INTERRUPTED');
      case PAYMENT_ERRORS.PAYMENT_IS_IN_PROGRESS:
        return t('PAYMENT_IS_LOCKED');
      case PAYMENT_ERRORS.MEMBER_ADDON_AMOUNT_NOT_MATCH:
        return t('Member_Addon_Amount_Not_Match');
      default:
        return tAdyen(error);
    }
  };

  const renderSaveForNextRecurring = () =>
    !isHideNextRecurring &&
    validCardForMakePayment.includes(membershipDetails.paymentMethod) && (
      <>
        <div className="adyen-form-upgrade__save-for-next">
          <FormControlLabel
            control={
              <Checkbox
                name="checkSaveForNextRecurring"
                onChange={handleCheckSaveForNextRecurring}
                checked={isCheckSaveForNextRecurring}
                inputRef={saveForNextCheckboxRef}
              />
            }
            label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_DEFAULT')}
          />
        </div>
        <div className="new-card-payment__note">
          <span>{t('SS_PAYMENT_NOTE')}: </span>
          <span>
            {t('SS_PAYMENT_NOTE_SAVING_CARD', {
              brandName: BRAND_CODE_ALIAS[membershipDetails.brandCode.toLocaleUpperCase()],
            })}
          </span>
        </div>
      </>
    );

  const renderAffirmativeSubcriptionTerms = () => (
    <div className="adyen-form-upgrade__affirmative-subscription-terms">
      <FormControlLabel
        control={
          <Checkbox
            name="checkAffirmativeSubcriptionTerms"
            onChange={handleCheckAffirmativeSubcriptionTerms}
            checked={isCheckAffirmativeSubcriptionTerms}
          />
        }
        label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_UPGRADE_MEMBERSHIP', {
          currency: membershipDetails.currencyCode,
          recurringFeeAmount: format(currencyFormat.value, recurring),
        })}
      />
      {!isCheckAffirmativeSubcriptionTerms && (
        <span className="reminder">{t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_REMIND_UPGRADE_AND_BUY_ADDON')}</span>
      )}
    </div>
  );

  return (
    <div className="adyen-form-upgrade">
      <div className="adyen-form-upgrade__options">
        <div className="adyen-form-upgrade__options__new-card-payment">
          <div className="new-card-payment__content">
            {error && <div className="payment-error">{renderError()}</div>}
            {renderAffirmativeSubcriptionTerms()}
            {renderSaveForNextRecurring()}
            <div id="dropin-container"></div>
          </div>
        </div>
        {showRegisterCardFragment &&
          validCardForMakePayment.includes(membershipDetails.paymentMethod) &&
          ((payment.primary && validCardForMakePayment.includes(payment.primary.paymentMethod) && (
            <div className="adyen-form-upgrade__options__exist-card-payment">
              <p className="adyen-form-upgrade__options__title">{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>

              <div className="adyen-form-upgrade__options__exist-card-payment__wrapper">
                {showExistCard && (
                  <>
                    <ExistCard
                      payment={payment.primary}
                      openConfirm={openConfirm}
                      isDisable={
                        !CheckExpiredCard(payment.primary.expiryDate) ||
                        restrictCards.includes(payment.primary.fiscalCode) ||
                        isAgreePolicies
                      }
                    />
                    <div className="adyen-form-upgrade__register-agree">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedB"
                            onChange={handleAgreeUsingRegisterCard}
                            checked={agreeUseRegisterCard.isAgree}
                          />
                        }
                        label={t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT_REGISTER_CARD_AGREE')}
                      />
                      {agreeUseRegisterCard.error && (
                        <span className="adyen-form-upgrade__register-agree--error">
                          {t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT_REGISTER_CARD_AGREE_ERROR')}
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div className="adyen-form-upgrade__options__exist-card-payment__3ds">
                  <div id="dropin-container1"></div>
                </div>
              </div>
            </div>
          )) ||
            (previousPayment.primary && validCardForMakePayment.includes(previousPayment.primary.paymentMethod) && (
              <div className="adyen-form-upgrade__options__exist-card-payment">
                <p className="adyen-form-upgrade__options__title">{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>
                <ExistCard payment={previousPayment.primary} isDisable />
              </div>
            )))}
      </div>
    </div>
  );
};

interface UpgradeMembershipStep3Prop {
  selectedItem: any;
  selectedClub: number;
  paynowAmount?: number;
  recurringAmount?: number;
  setLockScreen: (bool: boolean) => void;
}
const UpgradeMembershipStep3: React.FC<UpgradeMembershipStep3Prop> = ({
  selectedItem,
  selectedClub,
  paynowAmount,
  recurringAmount,
  setLockScreen,
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();
  // //redux store
  const {
    clearPaymentState,
    verifyAfterThreeDS,
    verifyAfterThreeDSRegisterCard,
    setOpenModal,
    callbackPayment,
    makePayment,
    payWithRegisterCard,
  } = paymentActions;
  const {
    paymentMethodsResponse,
    makePaymentSuccess,
    paymentResponse,
    paymentData,
    error,
    isOpenConfirm,
    loadingCallback,
    doneMakePayment,
    donePayWithRegisterCard,
    payWithRegisterCardSuccess,
    callbackConfirm,
    callbackPaymentRes,
  } = useTypedSelector((state) => state.payment);
  const { regexs } = useTypedSelector((state) => state.personal);
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const { paymentAction } = useTypedSelector((state) => state.payment);
  const notification = useTypedSelector((state) => state.notification);

  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [showRegisterCardFragment, setShowRegisterCardFragment] = useState(false);
  const [openConfirmPayExistCard, setOpenConfirmPayExistCard] = useState(false);
  const [showExistCard, setShowExistCard] = useState(true);
  const [showCheckboxSaveForNext, setShowCheckboxSaveForNext] = useState(true);
  const [agreeUseRegisterCard, setAgreeUseRegisterCard] = useState({ isAgree: false, error: false });
  const [agreeTnc, setAgreeTnC] = useState(false);
  const [agreeHealthStatement, setAgreeHealthStatement] = useState(false);
  const [isFirstTimeCallback, setIsFirstTimeCallback] = useState(true);
  const [isCheckAffirmativeSubcriptionTerms, setIsCheckAffirmativeSubcriptionTerms] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const allowProccessPaymentRef = useRef<boolean>(true);
  const saveForNextCheckboxRef = useRef<HTMLInputElement>(null);
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  }; 
  const amount = useMemo(() => paynowAmount || Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)), [
    paynowAmount,
  ]);

  const recurring = useMemo(
    () =>
      recurringAmount ||
      JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW) || '').recurringAmount,
    [recurringAmount]
  );
  const hideClubRules = useMemo(
    () =>
      ['HK', 'SG'].includes(membershipDetails.countryCode?.toUpperCase()) &&
      ['GR', '5E'].includes(membershipDetails.brandCode),
    [membershipDetails]
  );

  const upgradedMembership = useMemo(() => {
    if (selectedItem) {
      return {
        startedDate: moment(selectedItem.startDate).format(SYSTEM_DATE_FORMAT),
        reciprocalCode: selectedItem.accessLevel.code,
        clusterId: selectedItem.accessLevel.clusterId,
        membershipLength: selectedItem.commitmentLength.duration,
        clubId: selectedClub,
        addonIds: selectedItem.addons.map((addon: any) => addon.id),
        addonClassIds: selectedItem.addonClass.map((addon: any) => addon.id),
        promotionCode: selectedItem.promotionCode,
        dateOfBirth: membershipDetails.dateOfBirth,
      };
    }
  }, [selectedItem, selectedClub]);

  const dispatch = useDispatch();
  const clearPaymentAction = (clearResponse?: boolean) => dispatch(clearPaymentState(clearResponse));

  const verifyThreeDSAddonAction = (body: VerifyThreeDSAddonBody) => dispatch(verifyAfterThreeDS(body));
  const verifyThreeDSRegisterCardAddonAction = (body: VerifyThreeDSAddonBody) =>
    dispatch(verifyAfterThreeDSRegisterCard(body));
  const setOpenModalAction = (isOpen: boolean, callback?: Function) => dispatch(setOpenModal(isOpen, callback));
  const makePaymentAddonAction = (
    amountValue: number,
    payment: {
      paymentMethod: any;
      browserInfo: any;
      paymentType: string;
    },
    enableRecurring: boolean,
    dropinForm: any,
    upgradedMembership: any
  ) => dispatch(makePayment(amountValue, payment, enableRecurring, dropinForm, undefined, upgradedMembership));
  const payWithRegisterCardAction = (amount: number, upgradedMembership: any) =>
    dispatch(payWithRegisterCard(amount, undefined, upgradedMembership));
  const callbackPaymentAction = (token: string) => dispatch(callbackPayment(token));

  useEffect(() => {
    // Catch PresentToShopper
    if (
      doneMakePayment === DONE_MAKE_PAYMENT_TYPES.UPGRADE ||
      donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.UPGRADE
    ) {
      setLockScreen(true);
      setIsVerifySuccess(false);
      emitNotify(
        enqueueSnackbar,
        t('SS_NOTIFICATION_MEMBERSHIP'),
        t('SS_NOTIFICATION_MEMBER_MEMBERSHIP_UPGRADED_SAVING'),
        'success'
      );
      setOpenModalAction(false);
    }
  }, [doneMakePayment, donePayWithRegisterCard]);

  useEffect(() => {
    if (notification.message) {
      emitNotify(
        enqueueSnackbar,
        t(notification.title),
        t(notification.message),
        notification.isSuccess ? 'checked' : 'error'
      );
    }
    if (notification.message === 'SS_NOTIFICATION_MEMBER_MEMBERSHIP_UPGRADED_SUCCESS') {
      history.push(MEMBERSHIP_URL);
    }
    dispatch(setNotification({ title: '', message: '', type: '', id: '', isSuccess: true }));
  }, [notification.id]);

  const removeQueryParam = (param: string) => {
    if (query.has(param)) {
      query.delete(param);
      history.replace({
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    const token = query.get(PERSONAL_CONSTANTS.TOKEN);

    if (token && !callbackPaymentRes && !loadingCallback && getProcessPurpose()) {
      callbackPaymentAction(token);
      removeQueryParam(PERSONAL_CONSTANTS.TOKEN);
    }
  }, [loadingCallback, callbackPaymentRes]);

  useEffect(() => {
    if (callbackPaymentRes && isFirstTimeCallback) {
      const { paRes, md, paymentMethodType, payload, resultCode, token, type } = callbackPaymentRes;
      setIsFirstTimeCallback(false);
      let paymentDt = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';
      if (
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT ||
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD
      ) {
        if (paymentDt.length > 0 && md && paRes) {
          verifyThreeDSAddonAction({
            paymentData: paymentDt,
            verifyData: { md, paRes },
            isVerifyForPayment: true,
            addonIds: undefined,
            upgradedMembership:
              upgradedMembership || JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || ''),
          });
        } else if (paymentMethodType && payload && resultCode && token && type) {
          verifyThreeDSAddonAction({
            paymentData: paymentDt,
            verifyData: { details: { payload: callbackPaymentRes.payload } },
            isVerifyForPayment: true,
            addonIds: undefined,
            upgradedMembership:
              upgradedMembership || JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || ''),
          });
        }
      }
    }
  }, [callbackPaymentRes]);

  useEffect(() => {
    if (
      (getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD ||
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT) &&
      isVerifySuccess
    ) {
      handleLeaveMakePayment();
    }
  }, [isVerifySuccess]);

  useEffect(() => {
    if (error && getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD) {
      setShowExistCard(true);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      allowProccessPaymentRef.current = true;
      setShowRegisterCardFragment(true);
    }
  }, [error]);

  useEffect(() => {
    const submitPaymentButton = document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement;
    if (submitPaymentButton) {
      submitPaymentButton.disabled = !(agreeHealthStatement && agreeTnc && isCheckAffirmativeSubcriptionTerms);
    }
  }, [agreeHealthStatement, agreeTnc, isCheckAffirmativeSubcriptionTerms]);

  useEffect(() => {
    const initMakePayment = async () => {
      if (
        paymentMethodsResponse &&
        !makePaymentSuccess &&
        !document.getElementById('dropin-container')!.hasChildNodes()
      ) {
        setShowRegisterCardFragment(true);
        const configuration: any = {
          paymentMethodsResponse: {
            paymentMethods: paymentMethodsResponse.paymentMethods.filter(
              (pm: any) => pm.type === PERSONAL_CONSTANTS.SCHEME
            ),
            groups: paymentMethodsResponse.groups,
          },
          clientKey: _env.ADYEN_KEY,
          locale: PERSONAL_CONSTANTS.EN_US,
          translations: translations(t, true),
          environment: _env.ADYEN_ENV,
          setStatusAutomatically: false,
          onSubmit: (state: any, component: any) => {
            if (!allowProccessPaymentRef.current) {
              return;
            }
            const { paymentMethod, shopperName, shopperEmail, browserInfo } = state?.data;
            let paymentMethodCustom = { ...paymentMethod };
            if (shopperEmail) {
              paymentMethodCustom = { ...paymentMethod, ...shopperName, shopperEmail };
            }
            const checkSaveForNext = !!saveForNextCheckboxRef.current?.checked;
            sessionStorage.setItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING, checkSaveForNext ? ANSWER.YES : ANSWER.NO);
            if (state.isValid) {
              setShowRegisterCardFragment(false);
              const payment = {
                paymentMethod: paymentMethodCustom,
                browserInfo: browserInfo,
                paymentType: paymentMethod.type,
              };
              const amount = paynowAmount || Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE));
              if (paymentMethod) {
                setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT);
                if (upgradedMembership) {
                  sessionStorage.setItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP, JSON.stringify(upgradedMembership));
                }
                makePaymentAddonAction(
                  amount,
                  payment,
                  checkSaveForNext,
                  component,
                  upgradedMembership
                    ? upgradedMembership
                    : JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || '')
                );
                allowProccessPaymentRef.current = false;
              }
            }
          },
          onChange: (state: any, dropin: any) => {
            const { isValid } = state;
            const verifyButton: any = document.getElementsByClassName('adyen-checkout__button')[0];
  
            if (verifyButton) {
              if (isValid) {
                verifyButton.classList.remove('adyen-checkout__button--gray-out');
              } else {
                verifyButton.classList.add('adyen-checkout__button--gray-out');
              }
            }
            allowProccessPaymentRef.current = true;
          },
  
          onError: (err: any) => {
            console.log('Error - ', err);
          },
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              positionHolderNameOnTop: false,
              hideCVC: false,
              name: t('SS_PAYMENT_PAYMENT_METHOD'),
            },
          },
        };
  
        const checkout = await AdyenCheckout(configuration);
        setImmediate(() => {
          if(elementExistInDom('#dropin-container')) {
            checkout &&
            checkout
              .create('dropin', {
                onSelect: (component: any) => {
                  if (component.props.name === t('SS_PAYMENT_PAYMENT_METHOD')) {
                    setShowCheckboxSaveForNext(true);
                  } else {
                    setShowCheckboxSaveForNext(false);
                  }
                },
              })
              .mount('#dropin-container');
          }
          setImmediate(() => {
            (document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement).disabled = true;
          });
        });
      }
    }
    initMakePayment();
  }, [makePaymentSuccess, paymentMethodsResponse]);

  useEffect(() => {
    if (makePaymentSuccess && Object.keys(paymentResponse).length !== 0) {
      setIsVerifySuccess(true);
    }
  }, [paymentResponse, error]);

  useEffect(() => {
    //3DS-2
    const initSubmitWithAdyen = async () => {
      if (
        paymentAction &&
        makePaymentSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleMakePaymentOnAdditionalDetails,
        };
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom('#dropin-container')) {
          checkout &&
          checkout
            .createFromAction({
              ...paymentAction
            }, {
              size: '05',
            })
            .mount('#dropin-container');
        }
      }
  
      if (
        paymentAction &&
        payWithRegisterCardSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleRegisterCardOnAdditionalDetails,
        };
  
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom('#dropin-container1')) {
          checkout &&
          checkout
            .createFromAction({
              ...paymentAction
            }, {
              size: '05',
            })
            .mount('#dropin-container1');
        }
        setShowExistCard(false);
      }
    }
    initSubmitWithAdyen();
  }, [paymentAction, isVerifySuccess, payWithRegisterCardSuccess, makePaymentSuccess]);

  const handleMakePaymentOnAdditionalDetails = (state: any, _: any) => {
    verifyThreeDSAddonAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
      addonIds: undefined,
      upgradedMembership:
        upgradedMembership || JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || ''),
    });
  };

  const handleRegisterCardOnAdditionalDetails = (state: any, _: any) => {
    verifyThreeDSRegisterCardAddonAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
      addonIds: undefined,
      upgradedMembership:
        upgradedMembership || JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || ''),
    });
  };

  useEffect(() => {
    if (paymentData) {
      localStorage.setItem(SESSION_STORAGE_NAMES.PAYMENT_DATA, paymentData);
    }
  }, [paymentData]);

  const handleLeaveMakePayment = () => {
    if (callbackConfirm) {
      callbackConfirm();
    } else {
      history.push(MEMBERSHIP_URL);
    }
  };

  const setProcessPurpose = (value: string) => {
    sessionStorage.setItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE, value);
  };

  const getProcessPurpose = () => {
    return sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
  };

  const handlePayWithExistingCard = () => {
    const amount = paynowAmount || Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE));
    if (agreeUseRegisterCard.isAgree) {
      if (upgradedMembership) {
        sessionStorage.setItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP, JSON.stringify(upgradedMembership));
      }
      payWithRegisterCardAction(
        amount,
        upgradedMembership || JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP) || '')
      );
      setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD);
    } else {
      setAgreeUseRegisterCard({ ...agreeUseRegisterCard, error: true });
    }
  };

  const handleClickTermConditions = (e: any) => {
    setAgreeTnC(e.target.checked);
  };

  const handleClickHealthStatement = (e: any) => {
    setAgreeHealthStatement(e.target.checked);
  };

  if (Object.keys(membershipDetails).length === 0) {
    return null;
  }

  return (
    <>
      <div className="upgrade-personal-detail">
        <div className="upgrade-personal-detail__term-and-conditions">
          <div className="upgrade-personal-detail__row--inline">
            <FormControl>
              <Checkbox name="checkTermAndCondition" onChange={handleClickTermConditions} checked={agreeTnc} />
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    hideClubRules
                      ? 'SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_TERMS_CONDITIONS_WITHOUT_CLUBRULES'
                      : 'SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_TERMS_CONDITIONS',
                    {
                      termsConditions: membershipDetails?.endPoints?.membershipTermsAndConditionsUrl,
                      privacyPolicy: membershipDetails?.endPoints?.privacyPolicyUrl,
                      clubRules: membershipDetails?.endPoints?.clubRulesUrl,
                      termsOfUse: membershipDetails?.endPoints?.termsAndConditionUrl,
                    }
                  ),
                }}
              />
            </FormControl>
            {!agreeTnc && (
              <span className="error">
                {t(
                  hideClubRules
                    ? 'SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_TERMS_CONDITIONS_WITHOUT_CLUBRULES_ERROR'
                    : 'SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_TERMS_CONDITIONS_ERROR'
                )}
              </span>
            )}
          </div>
          <div className="upgrade-personal-detail__row--inline">
            <FormControl>
              <Checkbox
                name="checkHealthStatement"
                onChange={handleClickHealthStatement}
                checked={agreeHealthStatement}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_HEALTH_STATEMENT', {
                    healthStatement: membershipDetails?.endPoints?.healthStatementUrl,
                  }),
                }}
              />
            </FormControl>
            {!agreeHealthStatement && (
              <span className="error">{t('SS_UPGRADE_MEMBERSHIP_PERSONAL_DETAIL_HEALTH_STATEMENT_ERROR')}</span>
            )}
          </div>
        </div>
      </div>
      <div className="upgrade-payment-container">
        <PaymentMerchant
          showExistCard={showExistCard}
          setOpenConfirmPayExistCard={setOpenConfirmPayExistCard}
          saveForNextCheckboxRef={saveForNextCheckboxRef}
          isHideNextRecurring={!showCheckboxSaveForNext || (paymentAction && makePaymentSuccess && !isVerifySuccess)}
          setAgreeUseRegisterCard={setAgreeUseRegisterCard}
          agreeUseRegisterCard={agreeUseRegisterCard}
          showRegisterCardFragment={showRegisterCardFragment}
          isAgreePolicies={!agreeTnc || !agreeHealthStatement || !isCheckAffirmativeSubcriptionTerms}
          isCheckAffirmativeSubcriptionTerms={isCheckAffirmativeSubcriptionTerms}
          setIsCheckAffirmativeSubcriptionTerms={setIsCheckAffirmativeSubcriptionTerms}
          recurring={recurring}
        />
        <div className="upgrade-payment__card__container">
          <div className="upgrade-payment__card">
            <div className="upgrade-payment__card__heading">
              <div>
                <div className="upgrade-payment__card__heading__title">
                  {t('SS_UPGRADE_MEMBERSHIP_TO_PAY_THIS_MONTH')}
                </div>
                <div className="upgrade-payment__card__heading__title--note">
                  {t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}
                </div>
              </div>
              <div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: membershipDetails.currencyCode,
                    amount: format(currencyFormat.value, amount),
                    perMonth: '',
                  })}
                </div>
              </div>
            </div>
            <div className="upgrade-payment__card__content">
              <div>
                <div className="upgrade-payment__card__content__title">
                  {t('SS_UPGRADE_MEMBERSHIP_TOTAL_MONTHLY_RECURRING_PAYMENT')}
                </div>
                <div className="upgrade-payment__card__content__title--note">
                  {t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}
                </div>
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: membershipDetails.currencyCode,
                  amount: format(currencyFormat.value, recurring),
                  perMonth: '',
                })}
              </div>
            </div>
            <div className="upgrade-payment__card__creadit-card-only">
              <span>
                <CardIcon />
              </span>
              <div>{t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT_CREDIT_CARD_ONLY')}</div>
            </div>
            <div className="upgrade-payment__card__secure-payment">
              <div className="upgrade-payment__card__secure-payment__title">
                <div>{t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT_SECURE_PROCESS')}</div>
              </div>
              <span>
                <SecureSSLIcon />
              </span>
              {t('SS_UPGRADE_MEMBERSHIP_MAKE_PAYMENT_SECURE_SSL')}
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        openModal={isOpenConfirm}
        handleOpenModal={setOpenModalAction}
        displayMessage={t('SS_CONFIRM_MESS')}
        confirmLabel={t('SS_LEAVE')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={() => {
          handleLeaveMakePayment();
          clearPaymentAction(true);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
        }}
      />

      <ConfirmModal
        openModal={openConfirmPayExistCard}
        handleOpenModal={setOpenConfirmPayExistCard}
        displayMessage={t('SS_PAYMENT_CONFIRM_PAY_EXISTING_CARD')}
        confirmLabel={t('SS_YES')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={handlePayWithExistingCard}
      />
    </>
  );
};

export default UpgradeMembershipStep3;
