export const getDescription = (action: any) => {
  if (action.payIdViaPhoneNumber) {
    return action.payIdViaPhoneNumber;
  }

  if (action.payIdViaEmail) {
    return action.payIdViaEmail;
  }

  if (action.accountFirstSix) {
    return "*".repeat(12) + action.accountLastFour;
  }

  if (!action.accountFirstSix) {
    return "*".repeat(5) + action.accountLastFour;
  }
};
