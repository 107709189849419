import { paymentActionTypes as actions } from "../actionTypes";

const initialState = {
  paymentMethodsResponse: null,
  error: null,
  paymentResponse: {},
  paymentData: "",
  isOpenConfirm: false,
  callbackConfirm: null,
  paymentAction: null,
  callbackPaymentRes: null,
  arrearInfo: null,
  loadingPayArrears: false,
  globalError: null,
  globalErrorGUID: null,
  loadingCallback: false,
  doneMakePayment: "",
  doneMakePaymentWithReceived: "",
  donePayWithRegisterCard: "",
  makePaymentSuccess: false,
  editPaymentSuccess: false,
  payWithRegisterCardSuccess: false,
  doneUpdatePayment: false,
  isEzyPayDisabled: false,
  ezyPayInfo: null,
};

export default function paymentReducer(
  state = initialState,
  action: actions.PaymentAction,
) {
  switch (action.type) {
    case actions.GET_PAYMENT_METHOD:
      return {
        ...state,
      };
    case actions.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethodsResponse: action.paymentMethodsResponse,
      };
    case actions.GET_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        globalError: action.error,
        globalErrorGUID: Date.now(),
      };
    case actions.EDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentData: action.paymentResponse.action?.paymentData || "",
        paymentAction: action.paymentResponse.action,
        paymentResponse: action.paymentResponse.additionalData
          ? {
              ...action.paymentResponse.additionalData,
              transactionId: action.paymentResponse.transactionId,
              resultCode: action.paymentResponse.resultCode,
            }
          : {},
        editPaymentSuccess: true,
        error: null,
        globalError: null,
      };
    case actions.EDIT_PAYMENT_FAIL:
      return {
        ...state,
        error: action.error,
        editPaymentSuccess: false,
        paymentData: "",
        paymentAction: null,
      };
    case actions.PAY_WITH_REGISTER_CARD_SUCCESS:
      return {
        ...state,
        paymentData: action.paymentResponse.action?.paymentData || "",
        paymentAction: action.paymentResponse.action,
        paymentResponse: action.paymentResponse.additionalData
          ? {
              ...action.paymentResponse.additionalData,
              transactionId: action.paymentResponse.transactionId,
              resultCode: action.paymentResponse.resultCode,
            }
          : {},
        payWithRegisterCardSuccess: true,
        error: null,
        globalError: null,
      };

    case actions.PAY_WITH_REGISTER_CARD_FAIL:
      return {
        ...state,
        error: action.error,
        payWithRegisterCardSuccess: false,
        paymentData: "",
        paymentAction: null,
      };
    case actions.DONE_UPDATE_PAYMENT:
      return {
        ...state,
        doneUpdatePayment: true,
      };
    case actions.CLEAR_PAYMENT_SUCCESS:
      return {
        ...state,
        editPaymentSuccess: false,
        doneUpdatePayment: false,
        paymentData: "",
        error: null,
        saveSuccess: false,
        callbackConfirm: null,
        paymentAction: null,
        callbackPaymentRes: null,
        makePaymentSuccess: false,
        payWithRegisterCardSuccess: false,
        globalError: null,
        paymentResponse: action.clearResponse ? {} : state.paymentResponse,
        doneMakePayment: "",
        doneMakePaymentWithReceived: "",
        donePayWithRegisterCard: "",
      };
    case actions.CALLBACK_PAYMENT:
      return {
        ...state,
        loadingCallback: true,
      };
    case actions.CALLBACK_PAYMENT_SUCCESS:
      return {
        ...state,
        callbackPaymentRes: action.response,
        loadingCallback: false,
      };
    case actions.CALLBACK_PAYMENT_FAIL:
      return {
        ...state,
        loadingCallback: false,
      };

    case actions.SET_OPEN_MODAL:
      return {
        ...state,
        isOpenConfirm: action.isOpen,
        callbackConfirm: action.callback,
      };
    case actions.GET_ARREARS_INFOMATION:
      return {
        ...state,
      };
    case actions.GET_ARREARS_INFOMATION_SUCCESS:
      return {
        ...state,
        arrearInfo: action.response,
      };
    case actions.GET_ARREARS_INFOMATION_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actions.MAKE_PAYMENT:
      return {
        ...state,
      };
    case actions.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentData: action.paymentResponse.action?.paymentData || "",
        paymentAction: action.paymentResponse.action,
        paymentResponse: action.paymentResponse.additionalData
          ? {
              ...action.paymentResponse.additionalData,
              transactionId: action.paymentResponse.transactionId,
              resultCode: action.paymentResponse.resultCode,
            }
          : {},
        makePaymentSuccess: true,
        error: null,
        globalError: null,
      };
    case actions.MAKE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.errorMess,
        makePaymentSuccess: false,
        paymentData: "",
        paymentAction: null,
      };
    case actions.SAVE_CARD_ONLINE_FAIL:
      return {
        ...state,
        globalError: action.error,
        globalErrorGUID: Date.now(),
      };
    case actions.DONE_MAKE_PAYMENT:
      return {
        ...state,
        doneMakePayment: action.purpose,
      };
    case actions.DONE_MAKE_PAYMENT_WITH_RECEIVE:
      return {
        ...state,
        doneMakePaymentWithReceived: action.purpose,
      };
    case actions.DONE_PAY_WITH_REGISTER_CARD:
      return {
        ...state,
        donePayWithRegisterCard: action.purpose,
      };
    case actions.UPDATE_EZYPAY_DISABLED:
      return {
        ...state,
        isEzyPayDisabled: action.isEzyPayDisabled,
      };
    case actions.UPDATE_EZYPAY_INFO:
      return {
        ...state,
        ezyPayInfo: action.ezyPayInfo,
      };
    default:
      return state;
  }
}
